import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Notice } from 'models';
import EformSectionList from './eform/EformSectionList';
import css from '../../Noticepage.module.scss';
import { Accordion } from 'components/accordion/Accordion';

interface Props {
  readonly notice?: Notice | null;
}

export default function EformAccordionPart(props: Props): ReactElement | null {
  const { notice } = props;
  const { t } = useTranslation('translations');
  const [isExpandedForPrint, setIsExpandedForPrint] = useState(false);

  useEffect(() => {
    window.addEventListener('beforeprint', () => setIsExpandedForPrint(true));
    window.addEventListener('afterprint', () => setIsExpandedForPrint(false));

    return () => {
      window.removeEventListener('beforeprint', () =>
        setIsExpandedForPrint(true)
      );
      window.removeEventListener('afterprint', () =>
        setIsExpandedForPrint(false)
      );
    };
  }, []);

  if (!notice) return null;

  const renderAccordions = () => {
    return notice.eform?.map((section) => {
      return (
        <Accordion
          key={section.sectionId}
          isExpanded={isExpandedForPrint}
          summary={`${t('notice.part')} ${section.sectionId}: ${section.label}`}
        >
          {<EformSectionList sections={section.sections} />}
        </Accordion>
      );
    });
  };

  return (
    <section className={css.accordions} data-cy="accordions">
      {renderAccordions()}
    </section>
  );
}
