export const SortOption = {
  RELEVANCE: 'RELEVANCE',
  PUBLICATION_DATE_DESC: 'PUBLICATION_DATE_DESC',
  PUBLICATION_DATE_ASC: 'PUBLICATION_DATE_ASC',
  DEADLINE: 'DEADLINE',
  ESTIMATED_VALUE_ASC: 'ESTIMATED_VALUE_ASC',
  ESTIMATED_VALUE_DESC: 'ESTIMATED_VALUE_DESC'
} as const;

export type SortOptionType = (typeof SortOption)[keyof typeof SortOption];
