import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import css from './FactBox.module.scss';
import { Notice } from 'models';
import useLocations from 'shared/useLocations';
import { formatNumbers } from 'shared/utils';

interface Props {
  readonly notice: Notice;
}

const Row = ({
  label,
  value
}: {
  label: string;
  value: string | JSX.Element;
}) => (
  <>
    <dt>{label}</dt>
    <dd>{value}</dd>
  </>
);

export default function Table({ notice }: Props): ReactElement {
  const { t } = useTranslation('translations');
  const { flatLocations } = useLocations();

  const { core, noticeType, locationId, id } = notice;
  const { estimatedValue, mainActivity } = core ?? {};

  const getLocations = () => {
    if (!locationId?.length || !flatLocations) return t('common.not_specified');

    const locations = locationId
      .map(
        (locationId) =>
          flatLocations.find((location) => location.id === locationId)?.name
      )
      .filter((location) => location !== undefined);

    if (!locations.length) return t('common.not_specified');

    return locations.join(', ');
  };

  const getEstimatedValue = () =>
    estimatedValue?.amount ? (
      <b>{`${formatNumbers(estimatedValue.amount.toString())} ${
        estimatedValue.code
      }`}</b>
    ) : (
      t('common.not_specified')
    );

  return (
    <dl className={css.description_list}>
      <Row label={t('notice.reference')} value={id} />
      <Row label={t('filter.value')} value={getEstimatedValue()} />
      {!!locationId?.length && (
        <Row label={t('notice.place')} value={getLocations()} />
      )}
      {noticeType && (
        <Row
          label={t('notice.contract_type')}
          value={t('subtype.' + noticeType.toLowerCase())}
        />
      )}
      {mainActivity && (
        <Row label={t('notice.main_activity')} value={t(mainActivity)} />
      )}
    </dl>
  );
}
