import React, { ForwardedRef, ReactElement, forwardRef } from 'react';
import { ReactComponent as SmallCrossIcon } from '../../images/icon/cross-small.svg';
import { ReactComponent as MagnifierIcon } from '../../images/icon/magnifier.svg';
import { useTranslation } from 'react-i18next';
import css from './SuggestionInput.module.scss';

interface Props {
  readonly query: string;
  readonly placeholder: string;
  readonly ariaControls: string;
  readonly testId: string;
  readonly onChange: (value: string) => void;
  readonly dropdownVisible: boolean;
  readonly onClear: () => void;
  readonly onSearch?: () => void;
  readonly activeDescendant?: string;
}

export default forwardRef(function SuggestionInput(
  props: Props,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement {
  const {
    query,
    placeholder,
    ariaControls,
    testId,
    onChange,
    onClear,
    onSearch,
    dropdownVisible,
    activeDescendant
  } = props;
  const { t } = useTranslation('translations');

  return (
    <div className={css.input_field}>
      <input
        name={testId}
        ref={ref}
        type="text"
        value={query}
        data-cy={testId}
        role="combobox"
        aria-autocomplete="list"
        aria-expanded={dropdownVisible}
        aria-owns={ariaControls}
        aria-controls={ariaControls}
        aria-label={placeholder}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        aria-activedescendant={activeDescendant}
        autoComplete="off"
      />
      <div className={css.button_wrapper}>
        {query !== '' && (
          <button
            aria-label={t('search.reset')}
            className={css.clearBtn}
            data-cy="clear-btn"
            onClick={onClear}
            onKeyDown={(event) => event.key === 'Enter' && onClear}
          >
            <SmallCrossIcon />
          </button>
        )}
        {onSearch && (
          <button aria-label={t('search.execute_label')} onClick={onSearch}>
            <MagnifierIcon />
          </button>
        )}
      </div>
    </div>
  );
});
