import React, { ReactElement, useEffect, useRef, useState } from 'react';

import css from './CpvNode.module.scss';
import { CpvCode } from 'models';
import { TreeViewState } from 'models/treeViewState';

interface Props {
  readonly cpvCode: CpvCode;
  readonly treeViewState: TreeViewState;
  readonly toggleTreeViewState: (state: TreeViewState) => void;
}

export default function CpvNode(props: Props): ReactElement {
  const { cpvCode, treeViewState, toggleTreeViewState } = props;
  const hasChildren = cpvCode.children && cpvCode.children.length > 0;
  const cpvNode = useRef<HTMLLIElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (treeViewState === 'expanded') {
      setIsOpen(true);
    } else if (treeViewState === 'collapsed') {
      setIsOpen(false);
    }
  }, [treeViewState]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleCpvCodeClick = () => {
    hasChildren && setIsOpen(!isOpen);
    toggleTreeViewState('indeterminate');
  };

  function handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (cpvNode.current === document.activeElement) {
        handleCpvCodeClick();
      }
    }
  }

  return (
    <li
      role="treeitem"
      key={cpvCode.id}
      data-cy={cpvCode.id}
      className={css.cpv_node}
      aria-expanded={isOpen}
      aria-selected={isOpen}
      tabIndex={0}
      ref={cpvNode}
    >
      <div
        className={`${css.label_container} ${hasChildren && css.hoverable}`}
        data-cy="cpv_label"
        onClick={handleCpvCodeClick}
        onKeyDown={(event) => event.key === 'Enter' && handleCpvCodeClick}
      >
        {hasChildren && <span className={css.chevron} />}
        {!hasChildren && <span className={css.no_children} />}
        <span className={css.cpv_label} lang="nb">
          <span className={css.cpv_id}>{cpvCode.id}</span>
          {cpvCode.label}
        </span>
      </div>
      {isOpen && hasChildren && (
        <ul role="group" className={css.nested_group}>
          {cpvCode.children.map((child) => (
            <CpvNode
              cpvCode={child}
              key={child.id}
              treeViewState={treeViewState}
              toggleTreeViewState={toggleTreeViewState}
            />
          ))}
        </ul>
      )}
    </li>
  );
}
