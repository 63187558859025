import React, { AnchorHTMLAttributes, ReactElement } from 'react';

type Props = AnchorHTMLAttributes<HTMLAnchorElement>;

export default function ExternalLink(props: Readonly<Props>): ReactElement {
  return (
    <a target="_blank" {...props}>
      {props.children}
    </a>
  );
}
