import React, { ReactElement, useEffect, useState } from 'react';

import useCPV from 'shared/useCPV';
import CpvNode from 'pages/cpvpage/elements/cpvTree/cpvNode/CpvNode';
import TreeActions from './treeActions/TreeActions';
import { TreeViewState } from 'models/treeViewState';
import CpvTreeSkeleton from 'pages/cpvpage/elements/cpvTree/CpvTreeSkeleton';

export default function CpvTree(): ReactElement {
  const { data, status } = useCPV();
  const [treeViewState, setTreeViewState] =
    useState<TreeViewState>('collapsed');

  const smBreakpoint = 768;
  const isSmBreakpoint = window.innerWidth < smBreakpoint;

  const [isSmallerScreen, setIsSmallerScreen] =
    useState<boolean>(isSmBreakpoint);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () =>
    setIsSmallerScreen(window.innerWidth < smBreakpoint);

  const toggleTreeViewState = (state: TreeViewState) => {
    setTreeViewState(state);
  };

  return (
    <>
      {status === 'loading' && <CpvTreeSkeleton isMobile={isSmallerScreen} />}
      {status === 'error' && <div>Error</div>}
      {status === 'success' && data && (
        <>
          {!isSmallerScreen && (
            <TreeActions toggleTreeViewState={toggleTreeViewState} />
          )}
          <ul role="tree" data-cy="cpv-tree">
            {data.map((cpvCode) => (
              <CpvNode
                cpvCode={cpvCode}
                key={cpvCode.id}
                treeViewState={treeViewState}
                toggleTreeViewState={toggleTreeViewState}
              />
            ))}
          </ul>
        </>
      )}
    </>
  );
}
