import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';

import css from './LanguageSelector.module.scss';
import { Language, LanguageType } from 'models';
import { usePageTitleAndLanguageContext } from 'context/pageTitleAndLanguageContext/PageTitleAndLanguageContext';

export default function LanguageSelector(): ReactElement {
  const { t } = useTranslation('translations');
  const { setLanguage } = usePageTitleAndLanguageContext();

  const menu = useRef<HTMLUListElement>(null);
  const languageButton = useRef<HTMLButtonElement>(null);
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => setMenuVisible(!menuVisible);

  const handleMenuItemClick = (language: LanguageType) => {
    changeLang(language);
    setMenuVisible(false);
  };

  const handleClickOutside = (event) => {
    if (
      menu.current &&
      !menu.current.contains(event.target) &&
      languageButton.current &&
      !languageButton.current.contains(event.target)
    ) {
      setMenuVisible(false);
    }
  };

  const getLanguage = (): LanguageType => {
    const chosenLanguage = localStorage.getItem('locale') ?? 'nb';
    return Language[chosenLanguage];
  };

  const getLanguages = (): LanguageType[] => {
    return Object.values(Language);
  };

  const changeLang = async (language: LanguageType) => {
    const selectedLang =
      Object.keys(Language).find((key) => Language[key] === language) ?? 'nb';

    localStorage.setItem('locale', selectedLang);
    changeLanguage(selectedLang);
    setLanguage(selectedLang);
  };

  return (
    <>
      <button
        type="button"
        className={css.language}
        onClick={toggleMenu}
        ref={languageButton}
        id="language_button"
        aria-label={`${t('header.lang')}: ${getLanguage()}`}
        aria-expanded={menuVisible}
        aria-owns="language_select"
      >
        <span className={css.button_text}>{getLanguage()}</span>
      </button>
      {menuVisible && (
        <ul
          ref={menu}
          id="language_select"
          data-cy="language_select"
          role="menu"
          aria-hidden={menuVisible}
          aria-labelledby="language_button"
          className={css.language_list}
        >
          <label>{t('header.lang_dropdown_title')}</label>
          <hr className={css.hl} />
          {getLanguages().map((lang, index) => (
            <li key={lang}>
              <button
                lang={lang === 'English' ? 'en' : 'nb'}
                role="menuitem"
                onClick={() => handleMenuItemClick(lang)}
                onBlur={() => {
                  if (getLanguages().length === index + 1) toggleMenu();
                }}
              >
                {lang}
              </button>
              <hr className={css.hl} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
