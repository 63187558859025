import React, { ReactElement } from 'react';
import { ReactComponent as ExitRightIcon } from '../../../../../../images/icon/exit-right.svg';

import css from './MultiSection.module.scss';
import ExternalLink from 'components/externalLink/ExternalLink';

interface SplitSectionProps {
  leftContent: any;
  rightContent: string;
}

export default function SplitSection(
  props: SplitSectionProps
): ReactElement | null {
  const { leftContent, rightContent } = props;

  const hasRightContent = () => rightContent !== '';

  const isLink = () =>
    rightContent.startsWith('https://') ||
    rightContent.startsWith('http://') ||
    rightContent.startsWith('www.');

  const getLink = () =>
    rightContent.startsWith('www.') ? `http://${rightContent}` : rightContent;

  return hasRightContent() ? (
    <section>
      <div className={css.content_container}>
        <section>
          <p>{leftContent}</p>
        </section>
        <section>
          {isLink() ? (
            <ExternalLink href={getLink()}>
              {rightContent}
              <ExitRightIcon aria-hidden={true} />
            </ExternalLink>
          ) : (
            <p>{rightContent}</p>
          )}
        </section>
      </div>
    </section>
  ) : null;
}
