import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import css from './Filters.module.scss';
import LocationSelector from './locationSelector/LocationSelector';
import ProcurementSelector from './procurementSelector/ProcurementSelector';
import StatusSelector from './statusSelector/StatusSelector';
import DatepickerRange from './datepickerRange/DatepickerRange';
import { ResponseFacets } from 'models';
import ContractNatureSelector from './contractNature/ContractNature';

interface Props {
  readonly facets: ResponseFacets | undefined;
}

export default function Filters({ facets }: Props): ReactElement {
  const { t } = useTranslation('translations');

  return (
    <section data-cy="filter-seksjon" className={css.filter}>
      <fieldset className={css.fieldset}>
        <legend>{t('filter.procurement')}</legend>
        <ProcurementSelector typeItems={facets?.type.items} />
      </fieldset>
      <fieldset className={classnames(css.fieldset, css.form_section)}>
        <legend>{t('filter.status.title')}</legend>
        <StatusSelector statusItems={facets?.status.items} />
      </fieldset>
      <fieldset className={classnames(css.fieldset, css.form_section)}>
        <legend>{t('filter.contract_nature.title')}</legend>
        <ContractNatureSelector
          contractNatureItems={facets?.contractNature.items}
        />
      </fieldset>
      <fieldset className={css.fieldset}>
        <legend>{t('filter.date')}</legend>
        <DatepickerRange />
      </fieldset>
      <fieldset className={css.fieldset}>
        <legend data-cy="label-location-selector">
          {t('filter.location')}
        </legend>
        <LocationSelector locationItems={facets?.locations.items} />
      </fieldset>
    </section>
  );
}
