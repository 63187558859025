import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import css from './Header.module.scss';
import LanguageSelector from './languageSelector/LanguageSelector';
import MenuSelector from './menuSelector/MenuSelector';

export default function Header(): ReactElement {
  const { t } = useTranslation('translations');

  return (
    <header className={css.header}>
      <div className={css.header_content}>
        <div className={css.top_section}>
          <Link to="/" className={css.logo}>
            <img src="/assets/images/logo.svg" alt={t('header.dfo_logo')} />
          </Link>
          <div className={css.dropdown}>
            <div className={css.menu_container}>
              <LanguageSelector />
            </div>
            <nav className={css.menu_container} aria-label={t('header.menu')}>
              <MenuSelector />
            </nav>
          </div>
        </div>
        <Link to="/" className={css.header_title}>
          <p className={css.app_title} lang="nb">
            {t('app.title')}
          </p>
          <p className={css.sub_title}>{t('app.subtitle')}</p>
        </Link>
      </div>
    </header>
  );
}
