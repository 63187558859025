export const Procurement = {
  PLANNING: 'PLANNING',
  NOTICE_ON_BUYER_PROFILE: 'NOTICE_ON_BUYER_PROFILE',
  ADVISORY_NOTICE: 'ADVISORY_NOTICE',
  COMPETITION: 'COMPETITION',
  QUALIFICATION_SCHEME: 'QUALIFICATION_SCHEME',
  PRE_ANNOUNCEMENT: 'PRE_ANNOUNCEMENT',
  ANNOUNCEMENT_OF_COMPETITION: 'ANNOUNCEMENT_OF_COMPETITION',
  DYNAMIC_PURCHASING_SCHEME: 'DYNAMIC_PURCHASING_SCHEME',
  RESULT: 'RESULT',
  ANNOUNCEMENT_OF_INTENT: 'ANNOUNCEMENT_OF_INTENT',
  ANNOUNCEMENT_OF_CONCLUSION_OF_CONTRACT:
    'ANNOUNCEMENT_OF_CONCLUSION_OF_CONTRACT',
  CHANGE_OF_CONCLUSION_OF_CONTRACT: 'CHANGE_OF_CONCLUSION_OF_CONTRACT',
  CANCELLED_OR_MISSING_CONCLUSION_OF_CONTRACT:
    'CANCELLED_OR_MISSING_CONCLUSION_OF_CONTRACT'
} as const;

export type ProcurementType = (typeof Procurement)[keyof typeof Procurement];

export interface ProcurementNode {
  parent: ProcurementType;
  children: ProcurementType[];
}

export const procurementList: ProcurementNode[] = [
  {
    parent: 'PLANNING',
    children: ['NOTICE_ON_BUYER_PROFILE', 'ADVISORY_NOTICE']
  },
  {
    parent: 'COMPETITION',
    children: [
      'PRE_ANNOUNCEMENT',
      'QUALIFICATION_SCHEME',
      'ANNOUNCEMENT_OF_COMPETITION',
      'DYNAMIC_PURCHASING_SCHEME'
    ]
  },
  {
    parent: 'RESULT',
    children: [
      'ANNOUNCEMENT_OF_INTENT',
      'ANNOUNCEMENT_OF_CONCLUSION_OF_CONTRACT',
      'CHANGE_OF_CONCLUSION_OF_CONTRACT',
      'CANCELLED_OR_MISSING_CONCLUSION_OF_CONTRACT'
    ]
  }
];
