import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as NeutralIcon } from '../../../../images/icon/neutral.svg';
import css from './EmptyState.module.scss';

export default function EmptyState(): ReactElement {
  const { t } = useTranslation('translations');

  return (
    <div className={css.wrapper}>
      <div className={css.iconWrapper}>
        <NeutralIcon aria-hidden={true} />
      </div>
      <h3>{t('search.no_results_header')}</h3>
      <p>{t('search.no_results_text')}</p>
    </div>
  );
}
