import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import css from './Cpvpage.module.scss';
import CpvTree from './elements/cpvTree/CpvTree';
import InfoBox from './elements/infoBox/InfoBox';
import { ReactComponent as ArrowUpIcon } from '../../images/icon/arrow-up.svg';

export default function Cpvpage(): ReactElement {
  const { t } = useTranslation('translations');
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    scrollToTop();
    window.addEventListener('scroll', setScrollButtonVisibility);

    return () => {
      window.removeEventListener('scroll', setScrollButtonVisibility);
    };
  }, []);

  const setScrollButtonVisibility = () => setShowButton(window.scrollY > 300);

  const scrollToTop = () => scrollTo({ top: 0 });

  const focusTopOfPage = () => document.getElementById('start-link')?.focus();

  return (
    <>
      <Breadcrumbs title={t('cpv.breadcrumb')} />
      <div className={css.cpv_container}>
        <h1 className={css.cpv_title}>{t('cpv.title')}</h1>
        <p className={css.cpv_desc}>{t('cpv.desc')}</p>
        <div className={css.cpv_tree}>
          <CpvTree />
        </div>
        <div className={css.cpv_fact_box}>
          <InfoBox />
        </div>
        {showButton && (
          <div className={css.btn_container}>
            <button
              onClick={() => {
                scrollToTop();
                focusTopOfPage();
              }}
            >
              {t('cpv.scroll_to_top')} <ArrowUpIcon />
            </button>
          </div>
        )}
      </div>
    </>
  );
}
