import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('locale') ?? 'nb',
    fallbackLng: 'nb',
    react: {
      useSuspense: true
    },
    ns: 'translations'
  });
