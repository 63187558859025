import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import css from './AboutData.module.scss';

export default function AboutData(): ReactElement {
  // TODO: Create i18n translation for paragraph text
  const { t } = useTranslation('translations');
  return (
    <section className={css.about_data}>
      <h2 className={css.header}>{t('doffindata.title_about')}</h2>
      <p className={css.paragraph}>{t('doffindata.desc_about')}</p>
    </section>
  );
}
