import { SEARCH, SUGGEST } from 'shared-config';
import { searchInstance } from './http-common';
import { useQuery } from 'react-query';
import { SuggestionBody, SuggestionResponse } from 'models';
import { useSearchParams } from 'react-router-dom';
import { facetsFromUrl } from './search-common';

const useSuggestions = (
  searchString: string,
  selectedCheckboxes?: string[]
) => {
  const [searchParams] = useSearchParams();

  const requestBody: SuggestionBody = {
    facets: selectedCheckboxes
      ? facetsFromUrl(searchParams, selectedCheckboxes)
      : facetsFromUrl(searchParams),
    searchString: searchString
  };

  const fetchSuggestions = async () => {
    return await searchInstance
      .post(`/${SEARCH}/${SUGGEST}`, requestBody)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  async function queryAndSetSuggestions(): Promise<SuggestionResponse> {
    const response = await fetchSuggestions();
    return response;
  }
  const isLongEnough = searchString.length >= 2;

  const { data, isError, isSuccess } = useQuery<SuggestionResponse, Error>(
    [searchString],
    queryAndSetSuggestions,
    { enabled: isLongEnough, keepPreviousData: true }
  );

  return {
    data,
    isError,
    isSuccess
  };
};
export default useSuggestions;
