import React, { ReactElement } from 'react';
import { ReactComponent as WarningIcon } from '../../../../images/icon/warning.svg';

import css from './WarningSign.module.scss';

interface Props {
  readonly title: string;
  readonly subtitle: string;
}

export default function WarningSign({ title, subtitle }: Props): ReactElement {
  return (
    <div className={css.warning} data-cy={'warning_sign'} role="status">
      <WarningIcon aria-hidden={true} />
      <div className={css.text}>
        <h2 className={css.title}>{title}</h2>
        <p className={css.subtitle}>{subtitle}</p>
      </div>
    </div>
  );
}
