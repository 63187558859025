import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

import css from './App.module.scss';
import Header from 'components/header/Header';
import Noticepage from './pages/noticepage/Noticepage';
import Frontpage from './pages/frontpage/Frontpage';
import Searchpage from './pages/searchpage/Searchpage';
import Cpvpage from 'pages/cpvpage/Cpvpage';
import Datapage from './pages/datapage/Datapage';
import Dataviewpage from './pages/datapage/dataviewpage/dataviewpage';
import Footer from './components/footer/Footer';
import { NotFound } from './components/notFound/NotFound';
import { Matomo, matomoInstance } from 'matomoInstance';
import {
  APPLICATION_INSIGHTS_CONNECTION_STRING,
  SEARCH,
  NOTICES,
  DATA,
  VIEW,
  CPV
} from 'shared-config';
import { PageHeadProvider } from 'context/pageTitleAndLanguageContext/PageTitleAndLanguageContext';
import { isInternalUrl, redirects } from 'redirects';
import Redirect from 'components/redirect/Redirect';
import { SkipToContent } from 'components/skipToContent/SkipToContent';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

if (APPLICATION_INSIGHTS_CONNECTION_STRING) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: APPLICATION_INSIGHTS_CONNECTION_STRING,
      extensions: [reactPlugin as any],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory }
      }
    }
  });
  appInsights.loadAppInsights();
}

function App() {
  useEffect(() => {
    redirects
      .filter((redirect) => !isInternalUrl(redirect.to))
      .forEach((redirect) => {
        if (window.location.pathname === redirect.from) {
          window.location.href = redirect.to;
        }
      });
  }, [window.location.pathname]);

  return (
    <Suspense fallback="">
      <PageHeadProvider>
        <Router>
          <SkipToContent href="#mainContent" />
          <Header />
          <main id="mainContent" className={css.main} tabIndex={-1}>
            <MatomoProvider value={matomoInstance}>
              <Matomo />
              <Routes>
                {redirects
                  .filter((redirect) => isInternalUrl(redirect.to))
                  .map((redirect) => (
                    <Route
                      key={redirect.from}
                      path={redirect.from}
                      element={<Redirect to={redirect.to} />}
                    />
                  ))}
                <Route path={'/'} element={<Frontpage />} />
                <Route path={`/${SEARCH}`} element={<Searchpage />} />
                <Route path={`/${CPV}`} element={<Cpvpage />} />
                <Route path={`/${NOTICES}/:id`} element={<Noticepage />} />
                <Route path={`/${DATA}`} element={<Datapage />} />
                <Route path={`/${DATA}/${VIEW}`} element={<Dataviewpage />} />
                <Route path={'/*'} element={<NotFound />} />
              </Routes>
            </MatomoProvider>
          </main>
          <Footer />
        </Router>
      </PageHeadProvider>
    </Suspense>
  );
}

export default App;
