import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

export const formatNumbers = (value: string) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatDate = (date: string) => {
  return dayjs(date).tz('Europe/Oslo').format('DD.MM.YYYY');
};

export const formatDateAndTime = (date: string) => {
  return dayjs(date).tz('Europe/Oslo').format('DD.MM.YYYY / HH:mm');
};

const sortFn = (a: string, b: string) => a.localeCompare(b);

export const isEqualLists = (listA: string[], listB: string[]) => {
  if (listA.length !== listB.length) return false;

  listA.sort(sortFn);
  listB.sort(sortFn);

  return JSON.stringify(listA) === JSON.stringify(listB);
};
