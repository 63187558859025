import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExitRightIcon } from '../../../../images/icon/exit-right.svg';

import css from './LinkList.module.scss';
import {
  ANSKAFFELSER_HVA_URL,
  ANSKAFFELSER_INNKJOPSLEDELSE_URL,
  ANSKAFFELSER_INNOVASJON_URL,
  ANSKAFFELSER_PROSESS_URL,
  ANSKAFFELSER_REGELVERK_URL,
  ANSKAFFELSER_SAMFUNNSANSVAR_URL
} from 'shared-config';
import ExternalLink from 'components/externalLink/ExternalLink';

type MenuItem = {
  text: string;
  to: string;
};

export default function LinkList(): ReactElement {
  const { t } = useTranslation('translations');

  const linkItemsLeft: MenuItem[] = [
    {
      text: t('link.menu_hva_skal_du_kjoepe'),
      to: ANSKAFFELSER_HVA_URL
    },
    {
      text: t('link.menu_anskaffelsesprosess'),
      to: ANSKAFFELSER_PROSESS_URL
    },
    {
      text: t('link.menu_avtaler_regelverk'),
      to: ANSKAFFELSER_REGELVERK_URL
    }
  ];

  const linkItemsRight: MenuItem[] = [
    {
      text: t('link.menu_innkjoepsledelse'),
      to: ANSKAFFELSER_INNKJOPSLEDELSE_URL
    },
    {
      text: t('link.menu_samfunnsansvar'),
      to: ANSKAFFELSER_SAMFUNNSANSVAR_URL
    },
    {
      text: t('link.menu_innovasjon'),
      to: ANSKAFFELSER_INNOVASJON_URL
    }
  ];

  const getLinkItems = (listItems: MenuItem[]) => {
    return (
      <div className={css.link_list_wrap}>
        {listItems.map((item: MenuItem) => {
          return (
            <ExternalLink
              key={item.to}
              href={item.to}
              className={css.link_item}
            >
              <span className={css.link_item_title}>{item.text}</span>
              <ExitRightIcon />
            </ExternalLink>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <h2 className={css.link_list_title}>{t('link.list_title')}</h2>
      <div className={css.link_container}>
        {getLinkItems(linkItemsLeft)}
        {getLinkItems(linkItemsRight)}
      </div>
    </div>
  );
}
