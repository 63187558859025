import React from 'react';
import classNames from 'classnames';
import css from './FeedbackBox.module.scss';
import { ReactComponent as EmailIcon } from '../../../../images/icon/email-feedback.svg';
interface MailBoxProps {
  email: string;
  subject?: string;
  body?: string;
  icon?: React.ReactNode;
  title: string;
  description: string;
}

const FeedbackBox: React.FC<MailBoxProps> = ({
  email,
  subject,
  body,
  title,
  description
}) => {
  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
    subject || ''
  )}&body=${encodeURIComponent(body || '')}`;

  return (
    <div className={classNames(css.mail_box)} data-cy="mailbox">
      <div className={css.text_content}>
        <h3 className={css.title}>{title}</h3>
        <p className={css.description}>{description}</p>
      </div>
      <div className={css.email_content}>
        <a href={mailtoLink} className={css.email_link}>
          <div>
            <EmailIcon />
          </div>
          <span className={css.email}>{email}</span>
        </a>
      </div>
    </div>
  );
};

export default FeedbackBox;
