import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Location } from 'models';
import { LOCATION_CODES } from 'shared-config';
import { searchInstance } from 'shared/http-common';

const sortByName = (locations: Location[] | undefined) =>
  locations?.sort((a, b) => a.name.localeCompare(b.name));

const sortLocations = (data: Location | undefined) => {
  sortByName(data?.children);
  data?.children?.forEach((child) => sortLocations(child));
};

function flattenLocations(location: Location) {
  return [location, ...location.children.flatMap(flattenLocations)];
}

const fetchLocationCodes = async () => {
  return await searchInstance
    .get(`/${LOCATION_CODES}`)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.statusText);
      }
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

function useLocations() {
  const { data, status } = useQuery<Location>(
    ['locationCodes'],
    fetchLocationCodes,
    {
      staleTime: Infinity
    }
  );

  const locations = useMemo(() => {
    sortLocations(data);
    return data?.children;
  }, [data]);

  const flatLocations: Location[] | undefined = useMemo(() => {
    return locations?.flatMap((location) => flattenLocations(location));
  }, [locations]);

  return { status, locations, flatLocations };
}

export default useLocations;
