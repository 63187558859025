import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LargeCrossIcon } from '../../../../images/icon/cross-big.svg';
import Filters from '../filters/Filters';
import css from './MobileFilter.module.scss';
import { formatNumbers } from 'shared/utils';
import { ResponseFacets } from 'models';

interface Props {
  readonly totalHits: number | undefined;
  readonly facets: ResponseFacets | undefined;
}

export default function MobileFilter(props: Props): ReactElement {
  const { totalHits, facets } = props;
  const { t } = useTranslation('translations');
  const [isOpen, setIsOpen] = useState(false);
  const filterContainer = useRef<HTMLDivElement>(null);
  const overlay = useRef<HTMLDivElement>(null);
  const formattedTotalHits = totalHits && formatNumbers(totalHits.toString());

  const toggleFilterMenu = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(true);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleClickOutside = (event) => {
    if (
      filterContainer.current &&
      !filterContainer.current.contains(event.target) &&
      overlay.current &&
      overlay.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  function handleKeyPress(event: KeyboardEvent) {
    isOpen && event.key === 'Escape' && setIsOpen(false);
  }

  const scrollWindowToTop = () =>
    window.scrollTo({ top: 0, behavior: 'smooth' });

  const closeFilter = () => {
    setIsOpen(false);
    scrollWindowToTop();
  };

  return (
    <>
      <button
        className={css.toggle_filter_btn}
        onClick={toggleFilterMenu}
        data-cy="mobile-filter"
      >
        {t('filter.title')}
      </button>
      {isOpen && (
        <>
          <div ref={overlay} id={css.overlay} />
          <div
            ref={filterContainer}
            className={css.filter_container}
            data-cy="filter-overlay"
          >
            <div className={css.filter_menu_header}>
              <div className={css.filter_header_wrapper}>
                <p>{t('search.filtering')}</p>
                <button
                  onClick={closeFilter}
                  className={css.exit_filter}
                  aria-label={t('filter.close')}
                >
                  <LargeCrossIcon />
                </button>
              </div>
              <hr />
            </div>
            <div className={css.filter_component}>
              <Filters facets={facets} />
            </div>
            <div className={css.show_results}>
              <hr />
              <button
                onBlur={() => setIsOpen(false)}
                onClick={closeFilter}
                className={css.show_filter}
              >
                {`${t('search.show')} ${formattedTotalHits ?? '0'} ${t(
                  'search.result'
                )}`}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
