import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import css from './SortingSelector.module.scss';
import { SortOptionType, SortOption } from 'models/sortOption';
import { useSearchParams } from 'react-router-dom';

export default function SortingSelector(): ReactElement {
  const { t } = useTranslation('translations');
  const [searchParams, setSearchParams] = useSearchParams();
  const sortingFromUrl = searchParams.get('sortBy');

  const selectedOption: SortOptionType =
    (sortingFromUrl as SortOptionType) ?? 'RELEVANCE';

  const handleSelect = (value: string) => {
    searchParams.set('sortBy', value);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  return (
    <div className={css.select_container}>
      <select
        onChange={(selected) => handleSelect(selected.target.value)}
        value={selectedOption}
        aria-label={t('search.sort')}
        className={css.select}
        id="sort_select"
        data-cy="sort_select"
      >
        {Object.values(SortOption).map((sortValue) => (
          <option key={sortValue} value={sortValue}>
            {t('sorting.' + sortValue)}
          </option>
        ))}
      </select>
      <div className={css.icon} aria-hidden={true} />
    </div>
  );
}
