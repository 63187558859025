import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import css from './Checkbox.module.scss';
import { CheckboxState } from 'models';
import { formatNumbers } from 'shared/utils';

interface Props {
  readonly type: any;
  readonly toggleFn: (type: any, isIndeterminate?: boolean) => void;
  readonly label?: string;
  readonly isChecked?: boolean;
  readonly isIndeterminate?: boolean;
  readonly isSearchPage?: boolean;
  readonly total?: number;
  readonly hasChildren?: boolean;
  readonly isParent?: boolean;
}

export default function Checkbox(props: Props): ReactElement {
  const {
    type,
    label,
    isChecked,
    isIndeterminate,
    toggleFn,
    isSearchPage,
    total,
    hasChildren,
    isParent
  } = props;
  const { t } = useTranslation('translations');
  const [checkboxState, setCheckboxState] =
    useState<CheckboxState>('unchecked');

  useEffect(() => {
    if (isChecked && isIndeterminate) {
      setCheckboxState('indeterminate');
    } else if (isChecked) {
      setCheckboxState('checked');
    } else {
      setCheckboxState('unchecked');
    }
  }, [isChecked, isIndeterminate]);

  const handleCheckboxClick = (type: any) => {
    toggleFn(type);

    checkboxState === 'unchecked' || checkboxState === 'indeterminate'
      ? setCheckboxState('checked')
      : setCheckboxState('unchecked');
  };

  const formatLabel = (isForAccessibility?: boolean) => {
    const accessibilityText = isForAccessibility ? t('search.result') : '';
    let procurementLevel = '';
    if (hasChildren) {
      procurementLevel = isParent ? 'type.' : 'subtype.';
    }
    if (isSearchPage) {
      return (
        `${label ?? t(procurementLevel + type.toLowerCase())} (${formatNumbers(
          (total ?? 0).toString()
        )})` + accessibilityText
      );
    } else {
      return label ?? t(procurementLevel + type.toLowerCase());
    }
  };

  const isCheckboxClickable =
    !total && checkboxState !== 'checked' && checkboxState !== 'indeterminate';

  return (
    <label
      className={classnames(
        css.checkbox_label,
        isSearchPage && isCheckboxClickable && css.no_hits
      )}
      data-cy={type}
    >
      <input
        checked={checkboxState === 'checked'}
        type="checkbox"
        name={type}
        onChange={() => handleCheckboxClick(type)}
        id={type}
        data-cy={type}
        data-testid={type}
        className={classnames(
          css.checkbox,
          isSearchPage && isCheckboxClickable ? css.checkbox_disabled : ''
        )}
        ref={(input) =>
          input && (input.indeterminate = checkboxState === 'indeterminate')
        }
        aria-label={formatLabel(true)}
      />
      {formatLabel(false)}
    </label>
  );
}
