import axios from 'axios';
import { BASE_URL, SEARCH_API_URL } from 'shared-config';

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-type': 'application/json'
  }
});

export const searchInstance = axios.create({
  baseURL: SEARCH_API_URL,
  headers: {
    'Content-type': 'application/json'
  }
});

export const buildUrl = (parts: string[]): string => {
  let url = parts.join('/');
  if (url.indexOf('://') === -1 && !url.startsWith('/')) {
    url = '/' + url;
  }
  return url.replace(':///', '://');
};
