import css from '../SuggestionList.module.scss';
import { ReactComponent as MagnifierIcon } from '../../../../images/icon/magnifier.svg';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface EmptySearchItemProps {
  readonly focusedIndex: number;
}

export default function EmptySearchItem(props: EmptySearchItemProps) {
  const { focusedIndex } = props;
  const { t } = useTranslation('translations');

  return (
    <Link
      className={classNames(
        css.empty_search_box,
        focusedIndex === 4 ? css.empty_search_box_focus : ''
      )}
      to={'/search'}
      data-cy="empty-search-link"
      data-testid="empty-search-link"
      aria-label={
        t('frontpage.search_title') + ': ' + t('frontpage.empty_search')
      }
    >
      <MagnifierIcon />
      <h2 className={css.empty_search_font} aria-hidden="true">
        {t('frontpage.empty_search')}
      </h2>
    </Link>
  );
}
