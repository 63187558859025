import React, { ReactElement } from 'react';
import classnames from 'classnames';

import cssSearchPage from './Searchpage.module.scss';
import css from 'components/skeleton/Skeleton.module.scss';
import Skeleton from 'components/skeleton/Skeleton';

const SkeletonElement = ({ width }: { width: string }) => (
  <span
    style={{
      width,
      height: '1.75rem'
    }}
    className={css.skeleton}
  />
);

const BottomRowSkeleton = () => (
  <div className={classnames(css.loading, cssSearchPage.bottom_row_skeleton)}>
    <span
      style={{
        width: '11rem',
        height: '1.75rem',
        borderRadius: '22px'
      }}
      className={css.skeleton}
    />
    <SkeletonElement width="3.5rem" />
    <SkeletonElement width="7rem" />
    <SkeletonElement width="6.5rem" />
    <SkeletonElement width="6.5rem" />
  </div>
);

const NoticeCardSkeleton = () => (
  <div className={cssSearchPage.notice_card_skeleton}>
    <Skeleton style={{ height: '1.25rem' }} />
    <Skeleton style={{ height: '1.875rem' }} />
    <Skeleton style={{ height: '5rem' }} />
    <BottomRowSkeleton />
  </div>
);

export default function SearchPageSkeleton(): ReactElement {
  return (
    <div className={cssSearchPage.search_page_skeleton}>
      <NoticeCardSkeleton />
      <NoticeCardSkeleton />
      <NoticeCardSkeleton />
    </div>
  );
}
