import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import css from './TreeActions.module.scss';
import { ReactComponent as CollapseIcon } from '../../../../../images/icon/contract.svg';
import { ReactComponent as ExpandIcon } from '../../../../../images/icon/expand.svg';
import { ReactComponent as ChevronRightIcon } from '../../../../../images/icon/arrow-left.svg';
import { TreeViewState } from 'models/treeViewState';

interface Props {
  readonly toggleTreeViewState: (toggleState: TreeViewState) => void;
}
export default function TreeActions({
  toggleTreeViewState
}: Props): ReactElement {
  const { t } = useTranslation('translations');

  return (
    <div className={css.tree_actions}>
      <button
        type="button"
        aria-label={`${t('cpv.back_to_search')} ${t('cpv.title')}`}
        className={css.back_button}
        onClick={() => window.history.back()}
        data-cy="back-button"
      >
        <ChevronRightIcon /> {t('cpv.back_to_search')}
      </button>
      <button
        type="button"
        aria-label={`${t('cpv.expand_label')} ${t('cpv.title')}`}
        className={css.inverted_icon_btn}
        onClick={() => toggleTreeViewState('expanded')}
        data-cy="expand-tree-btn"
      >
        {t('cpv.expand_label')} <ExpandIcon />
      </button>
      <button
        type="button"
        aria-label={`${t('cpv.collapse_label')} ${t('cpv.title')}`}
        className={css.inverted_icon_btn}
        onClick={() => toggleTreeViewState('collapsed')}
        data-cy="collapse-tree-btn"
      >
        {t('cpv.collapse_label')} <CollapseIcon />
      </button>
    </div>
  );
}
