import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import css from './NewVersionAlert.module.scss';
import { ReactComponent as WarningIcon } from '../../../../images/icon/warning.svg';

export default function NewerVersionAlert(): ReactElement {
  const { t } = useTranslation('translations');

  return (
    <div
      className={classNames(css.newer_version_title, css.newer_version_exists)}
      data-cy="newer-version"
    >
      <WarningIcon aria-hidden={true} />
      <p>{t('notice.newer_version_exists')}</p>
    </div>
  );
}
