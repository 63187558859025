import React from 'react';
import { useTranslation } from 'react-i18next';

import GraphCard from './graphCard/GraphCard';
import css from './GraphView.module.scss';
import { graphViewList } from '../../../../shared-config';

export default function GraphView() {
  const { t } = useTranslation('translations');

  return (
    <React.Fragment key={'graphview_component'}>
      <h2 className={css.heading}>{t('doffindata.title_graphcard')}</h2>
      <div className={css.graph_list}>
        {graphViewList.map((element) => (
          <GraphCard graph={element} key={element.name} />
        ))}
      </div>
    </React.Fragment>
  );
}
