import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classnames from 'classnames';
import dayjs from 'dayjs';

import css from './Datepicker.module.scss';

export type DateError = 'max_date' | 'invalid_date';

interface Props {
  readonly label: string;
  readonly setError: (dateError: DateError | null) => void;
  readonly minDate?: string | null;
  readonly maxDate?: string | null;
  readonly name: string;
}

export default function Datepicker(props: Props): ReactElement {
  const { label, minDate, maxDate, setError, name } = props;
  const [date, setDate] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const fromDateFromUrl = searchParams.get('fromDate');
  const toDateFromUrl = searchParams.get('toDate');
  const firstRender = useRef(true);

  const dateFormat = 'YYYY-MM-DD';
  const hundredYearsInPast = dayjs().subtract(100, 'year').format(dateFormat);
  const hundredYearsInFuture = dayjs().add(100, 'year').format(dateFormat);

  const hasfromDateError =
    toDateFromUrl &&
    name === 'fromDate' &&
    new Date(date) > new Date(toDateFromUrl);

  const hasToDateError =
    fromDateFromUrl &&
    name === 'toDate' &&
    new Date(date) < new Date(fromDateFromUrl);

  const hasBoundaryDateError =
    (name === 'fromDate' && new Date(date) < new Date(hundredYearsInPast)) ||
    (name === 'toDate' && new Date(date) > new Date(hundredYearsInFuture));

  const hasError = !!hasToDateError || !!hasBoundaryDateError;

  useEffect(() => {
    setDate(updateInputValue());
  }, [fromDateFromUrl, toDateFromUrl]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    const delayFn = setTimeout(() => {
      if (hasBoundaryDateError) {
        setError('invalid_date');
        return;
      }
      if (hasfromDateError) {
        updateDateInUrl('toDate', date);
      }
      if (hasToDateError) {
        setError('max_date');
        return;
      }
      updateDateInUrl(name, date);
      setError(null);
    }, 400);
    return () => clearInterval(delayFn);
  }, [date]);

  const updateInputValue = (): string => {
    const value = name === 'fromDate' ? fromDateFromUrl : toDateFromUrl;
    if (value === null || value === undefined) return '';
    return value;
  };

  const shouldUpdateUrl = (name: string, date: string) => {
    const dateFromUrl = name === 'fromDate' ? fromDateFromUrl : toDateFromUrl;

    return dateFromUrl !== date;
  };

  const updateDateInUrl = (name: string, date: string) => {
    if (shouldUpdateUrl(name, date)) {
      setSearchParams((searchParams) => {
        date !== '' ? searchParams.set(name, date) : searchParams.delete(name);
        searchParams.set('page', '1');
        return searchParams;
      });
    }
  };

  const getMinDate = () => {
    if (minDate) {
      return minDate;
    }
  };

  const getMaxDate = () => {
    if (maxDate) {
      return maxDate;
    }
  };

  return (
    <input
      data-cy="datepicker"
      className={classnames(css.date_input, !!hasError && css.date_error)}
      placeholder={label}
      aria-label={label}
      aria-invalid={!!hasError}
      value={date}
      onChange={(event) => setDate(event.target.value)}
      type="date"
      name={label}
      min={getMinDate()}
      max={getMaxDate()}
    />
  );
}
