import { Navigate, Params } from 'react-router-dom';
import { useParams } from 'react-router';

export interface Props {
  to: string;
  state?: any;
}

export const Redirect = ({ to, state }: Props) => {
  const params = useParams();

  const updateTo = (to: string, params: Readonly<Params<string>>) => {
    const entries = Object.entries(params);
    let path = `${to}`;

    entries.forEach(([key, value]) => {
      path = path.replace(`:${key}`, `${value}`);
    });

    return path;
  };

  return <Navigate to={updateTo(to, params)} state={state} replace />;
};

export default Redirect;
