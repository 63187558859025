import React from 'react';
import { useTranslation } from 'react-i18next';

import css from './SkipToContent.module.scss';

type Props = {
  href: string;
};

export const SkipToContent = ({ href }: Props) => {
  const { t } = useTranslation('translations');

  return (
    <a href={href} className={css.skip_link} target="_self">
      {t('app.skip_to_content')}
    </a>
  );
};
