import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as ExitRightIcon } from '../../images/icon/exit-right.svg';
import { ReactComponent as LogoWhite } from '../../images/logo-white.svg';

import css from './Footer.module.scss';
import ExternalLink from 'components/externalLink/ExternalLink';
import {
  ANSKAFFELSER_BRUKERVEILEDNING_URL,
  ANSKAFFELSER_OM_URL,
  ANSKAFFELSER_OPPDRAGSGIVERE_URL,
  ANSKAFFELSER_TILBYDERE_URL,
  DFO_ENGLISH_URL,
  DFO_OM_URL,
  DFO_PERSONVERN_URL,
  DFO_URL
} from 'shared-config';

interface FooterLink {
  to: string;
  text: string;
}

export default function Footer(): ReactElement {
  const { t } = useTranslation('translations');

  const infoLinks: FooterLink[] = [
    {
      to: ANSKAFFELSER_OPPDRAGSGIVERE_URL,
      text: t('link.menu_for_oppdragsgivere')
    },
    {
      to: ANSKAFFELSER_TILBYDERE_URL,
      text: t('link.menu_for_tilbydere')
    },
    {
      to: ANSKAFFELSER_BRUKERVEILEDNING_URL,
      text: t('link.menu_user_guide')
    },
    {
      to: ANSKAFFELSER_OM_URL,
      text: t('link.menu_om_doffin')
    },
    {
      to: 'https://dfo.no/tilgjengelighet',
      text: t('link.accessibility')
    }
  ];

  const externalLinks: FooterLink[] = [
    { to: DFO_OM_URL, text: t('footer.about_dfo') },
    { to: DFO_PERSONVERN_URL, text: t('footer.privacy') },
    {
      to: DFO_ENGLISH_URL,
      text: 'English'
    }
  ];

  return (
    <footer>
      <div className={css.footer_container}>
        <div className={css.content_wrapper}>
          <nav className={css.left_column} aria-label={t('footer.links')}>
            <Link to="/" className={css.application_title}>
              <p>
                <b lang="nb">{t('app.title')}</b>
              </p>
              <p>{t('app.subtitle')}</p>
            </Link>

            <ul className={css.link_list}>
              {infoLinks.map((link) => (
                <li key={link.to}>
                  <ExternalLink href={link.to}>
                    {link.text}
                    <ExitRightIcon />
                  </ExternalLink>
                </li>
              ))}
            </ul>
          </nav>

          <div className={css.right_column}>
            <ExternalLink
              href={DFO_URL}
              className={css.logo_link}
              aria-label={t('footer.dfo_link_label')}
            >
              <LogoWhite />
            </ExternalLink>

            <address className={css.contact_info}>
              <a href="tel:+4740007997">{t('footer.phone_abbr')}: 400 07 997</a>
              <a href="mailto:postmottak@dfo.no">
                {t('footer.email')}: postmottak@dfo.no
              </a>
              <p>Org. nr. 986252932</p>
            </address>

            <ul className={css.external_link_list}>
              {externalLinks.map((link) => (
                <li key={link.to}>
                  <ExternalLink
                    href={link.to}
                    lang={
                      link.text === 'English'
                        ? 'en'
                        : `${localStorage.getItem('locale') ?? 'nb'}`
                    }
                  >
                    {link.text}
                    <ExitRightIcon />
                  </ExternalLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
