import React, { ReactElement, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import css from './DatepickerRange.module.scss';
import Datepicker, { DateError } from './datepicker/Datepicker';
import FilterError from '../filterError/FilterError';

export default function DatepickerRange(): ReactElement {
  const [dateError, setDateError] = useState<DateError | null>(null);
  const { t } = useTranslation('translations');
  const dateFormat = 'YYYY-MM-DD';

  const [searchParams] = useSearchParams();
  const fromDateFromUrl = searchParams.get('fromDate');

  const hundredYearsInPast = dayjs().subtract(100, 'year').format(dateFormat);
  const hundredYearsInFuture = dayjs().add(100, 'year').format(dateFormat);

  const errorMessage = useMemo(() => {
    switch (dateError) {
      case 'max_date':
        return t('filter.max_date');
      case 'invalid_date':
        return t('filter.invalid_date');
      default:
        return '';
    }
  }, [dateError]);

  return (
    <div className={css.datepicker_wrapper}>
      {dateError !== null && <hr className={css.error_line}></hr>}
      <div className={css.datepicker_container}>
        <Datepicker
          label={t('filter.from')}
          name="fromDate"
          minDate={hundredYearsInPast}
          setError={setDateError}
        />
        <Datepicker
          label={t('filter.to')}
          name="toDate"
          minDate={fromDateFromUrl}
          maxDate={hundredYearsInFuture}
          setError={setDateError}
        />
      </div>
      {dateError !== null && <FilterError errorMessage={errorMessage} />}
    </div>
  );
}
