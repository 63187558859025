import React, { ReactElement, useState } from 'react';
import styles from './ProcurementTimeline.module.scss';
import { ReactComponent as Document } from '../../../../images/icon/documents-dblue.svg';
import { ReactComponent as DocumentFaded } from '../../../../images/icon/documents-faded.svg';
import { ReactComponent as ChevronUp } from '../../../../images/icon/chevron-up-white.svg';
import { ReactComponent as ChevronDown } from '../../../../images/icon/chevron-down-white.svg';
import { ReactComponent as ArrowUp } from '../../../../images/icon/doublearrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../../images/icon/doublearrow-down.svg';
import { ReactComponent as ArrowRight } from '../../../../images/icon/arrow-right-lblue.svg';

import { ProcurementTimelineData } from '../../../../models';
import { buildUrl } from 'shared/http-common';
import { NOTICES } from 'shared-config';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'shared/utils';
import classNames from 'classnames';

interface Props {
  readonly procurementTimeline?: ProcurementTimelineData[];
}

const ProcurementTimeline = ({
  procurementTimeline = []
}: Props): ReactElement => {
  const { t } = useTranslation('translations');
  const flattenedTimeline = procurementTimeline.flat();

  const titleMap: { [key: string]: string } = {
    RESULT: t('type.result'),
    PLANNING: t('type.planning'),
    COMPETITION: t('type.competition')
  };

  const accordionData = flattenedTimeline.reduce(
    (acc, item) => {
      const type = Array.isArray(item.allType) ? item.allType[1] : null;

      if (type && titleMap[type]) {
        const title = titleMap[type];

        if (!acc[title]) {
          acc[title] = {
            type,
            title,
            links: []
          };
        }

        acc[title].links.push({
          name: `Kunngjøring ${item.id}`,
          date: item.publicationDate,
          activePage: item.selectedNotice,
          id: item.id
        });
      }

      return acc;
    },
    {} as Record<
      string,
      {
        type: string;
        title: string;
        links: {
          name: string;
          date: string;
          activePage: boolean;
          id: string;
        }[];
      }
    >
  );

  const requiredPhases = ['PLANNING', 'COMPETITION', 'RESULT'];
  requiredPhases.forEach((phase) => {
    const title = titleMap[phase];
    if (!accordionData[title]) {
      accordionData[title] = { type: phase, title, links: [] };
    }
  });

  ['Resultat', 'Planlegging'].forEach((phase) => {
    if (accordionData[phase]) {
      accordionData[phase].links.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
    }
  });

  const accordionItems = Object.values(accordionData).sort((a, b) => {
    return requiredPhases.indexOf(a.type) - requiredPhases.indexOf(b.type);
  });

  const initialExpandedIndex = accordionItems.findIndex((item) =>
    item.links.some((link) => link.activePage)
  );

  const [expandedIndexes, setExpandedIndexes] = useState<number[]>(
    initialExpandedIndex !== -1 ? [initialExpandedIndex] : []
  );

  const handleToggle = (index: number) => {
    setExpandedIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index]
    );
  };

  return (
    <div className={styles.timelineContainer}>
      <h2 className={styles.timelineTitle}>{t('timeline.heading')}</h2>
      <div className={styles.accordion}>
        {accordionItems.map((item, index) => (
          <AccordionItem
            key={index}
            title={item.title}
            links={item.links}
            isActive={expandedIndexes.includes(index)}
            isHighlighted={item.links.some((link) => link.activePage)}
            onToggle={() => handleToggle(index)}
            linkCount={item.links.length}
          />
        ))}
      </div>
    </div>
  );
};

const AccordionItem = ({
  title,
  links,
  isActive,
  isHighlighted,
  onToggle,
  linkCount
}: {
  title: string;
  links: { name: string; date: string; activePage: boolean; id: string }[];
  isActive: boolean;
  isHighlighted: boolean;
  onToggle: () => void;
  linkCount: number;
}) => {
  const [showAll, setShowAll] = useState(false);
  const [hoveredLinkIndex, setHoveredLinkIndex] = useState<number | null>(null);

  const toggleShowAll = () => setShowAll(!showAll);
  const { t } = useTranslation('translations');
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  const displayedLinks = showAll ? links : links.slice(0, 5);

  return (
    <div
      className={`${styles.accordionItem} ${
        isHighlighted ? styles.highlighted : ''
      }`}
    >
      <div
        className={styles.accordionHeader}
        role="button"
        tabIndex={0}
        onClick={onToggle}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onToggle();
          }
        }}
      >
        <span
          className={`${styles.circle} ${
            isHighlighted ? styles.activeCircle : ''
          }`}
        ></span>
        <div className={styles.headerContent}>
          <span className={styles.title}>{title}</span>
          <div className={styles.linkCountContainer}>
            <span className={styles.linkCount}>{linkCount}</span>
            <Document className={styles.documentIcon} />
          </div>
        </div>
        <div className={styles.chevron}>
          {isActive ? <ChevronUp /> : <ChevronDown />}
        </div>
      </div>

      {isActive && (
        <div className={styles.accordionContent}>
          {links.length === 0 ? (
            <div className={styles.emptyMessage}>
              <div className={styles.emptyMessage}>
                {t('timeline.empty_message', { type: title.toLowerCase() })}
              </div>
            </div>
          ) : (
            <>
              {displayedLinks.map((link, index) => (
                <Link
                  key={index}
                  to={buildUrl([NOTICES, link.id])}
                  onClick={handleLinkClick}
                  className={classNames(styles.linkItem, {
                    [styles.activeLink]: link.activePage
                  })}
                  onMouseEnter={() => setHoveredLinkIndex(index)}
                  onMouseLeave={() => setHoveredLinkIndex(null)}
                >
                  {link.activePage || hoveredLinkIndex === index ? (
                    <Document
                      data-testid="normalIcon"
                      className={styles.linkDocumentIconLarge}
                    />
                  ) : (
                    <DocumentFaded
                      data-testid="fadedIcon"
                      className={styles.linkDocumentIconLarge}
                    />
                  )}
                  <div className={styles.linkTextContainer}>
                    <span className={styles.linkName}>{link.name}</span>
                    <span className={styles.linkDate}>
                      {formatDate(link.date)}
                    </span>
                  </div>
                  {hoveredLinkIndex === index && (
                    <ArrowRight className={styles.arrowIcon} />
                  )}
                </Link>
              ))}
              {links.length > 5 && (
                <button
                  onClick={toggleShowAll}
                  className={styles.showMoreButton}
                >
                  <span>
                    {showAll
                      ? t('timeline.show_less')
                      : t('timeline.show_more')}
                  </span>
                  {showAll ? (
                    <ArrowUp className={styles.icon} />
                  ) : (
                    <ArrowDown className={styles.icon} />
                  )}
                </button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProcurementTimeline;
