import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { StatusType } from 'models';
import css from './ChipLine.module.scss';

interface Props {
  readonly status: StatusType | undefined;
  readonly type: any;
  readonly allTypes: any;
}

export default function ChipLine({ status, type, allTypes }: Props) {
  const { t } = useTranslation('translations');
  let mainType: string;
  let mainTypeClass: any;
  const subtype = t('subtype.' + type.toLowerCase());
  const statusClass = status?.toLowerCase() ?? '';

  const getStatusText = () => {
    if (status) {
      switch (status) {
        case 'ACTIVE':
          return t(`filter.status.active`);
        case 'AWARDED':
          return t(`filter.status.awarded`);
        case 'EXPIRED':
          return t(`filter.status.expired`);
        case 'CANCELLED':
          return t(`filter.status.cancelled`);
      }
    } else {
      return '';
    }
  };

  const getMainType = () => {
    allTypes.forEach((element: string) => {
      if (
        element === 'RESULT' ||
        element === 'COMPETITION' ||
        element === 'PLANNING'
      ) {
        mainType = t('type.' + element.toLowerCase());
        mainTypeClass = element.toLowerCase();
      }
    });
    return mainType;
  };

  return (
    <div className={css.chipline}>
      <p
        aria-label={`${t('notice.contract_type')}: ${getMainType()}`}
        className={classnames(css.chip, css.type, css[mainTypeClass])}
      >
        {getMainType().toUpperCase()}
      </p>
      <p
        aria-label={`${t('filter.procurement')}: ${t(
          'subtype.' + type.toLowerCase()
        )}`}
        className={classnames(css.chip, css.type)}
      >
        {subtype.toUpperCase()}
      </p>
      {status && (
        <span
          data-cy="badge"
          className={classnames(css.chip, css.status, css[statusClass])}
          aria-label={`${t('filter.status.title')}: ${getStatusText()}`}
        >
          {getStatusText().toUpperCase()}
        </span>
      )}
    </div>
  );
}
