import { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import css from './DataDashboard.module.scss';
import getEmbedToken from '../../../../shared/useData';
import { dashboardList } from '../../../../shared-config';

export default function DataDashboard(): ReactElement {
  const { t } = useTranslation('translations');
  const reportContainerGraph = useRef(null);
  getEmbedToken(dashboardList[0].reportId, reportContainerGraph);

  return (
    <div className={css.data_dashboard}>
      <h2 className={css.title}>{t('doffindata.title_dashboard')}</h2>
      <div className={css.grid_container}>
        <div
          ref={reportContainerGraph}
          aria-label={dashboardList[0].title}
          data-cy="report-container-graph"
          className={css.report}
        ></div>
      </div>
    </div>
  );
}
