import React from 'react';
import classNames from 'classnames';

import SearchTips from './searchTips/SearchTips';
import CPVLink from './cpvLink/CPVLink';
import css from './HelpLinks.module.scss';

interface Props {
  readonly isFrontPage?: boolean;
}

export default function HelpLinks({ isFrontPage }: Props) {
  return (
    <div
      data-cy="cpv-filter"
      className={classNames(
        css.cpv_label_wrapper,
        isFrontPage ? css.frontpage : ''
      )}
    >
      <CPVLink />
      <SearchTips />
    </div>
  );
}
