import React, { ReactElement, useMemo } from 'react';

import Checkbox from 'components/checkbox/Checkbox';
import { ProcurementNode, ProcurementType, TypeItem } from 'models';

interface Props {
  readonly type: ProcurementNode;
  readonly toggleFn: (type: ProcurementType, isIndeterminate?: boolean) => void;
  readonly typeItems: TypeItem[] | undefined;
  readonly selectedTypes?: string[];
}

export default function ProcurementItem({
  type,
  selectedTypes,
  toggleFn,
  typeItems
}: Props): ReactElement {
  const { parent, children } = type;

  const checkIfIndeterminate = (children: ProcurementType[]) => {
    const numberOfChildren = children.length;
    const checkedChildren = children.filter(
      (child) => selectedTypes?.includes(child)
    ).length;
    const isFullyChecked = numberOfChildren === checkedChildren;
    return checkedChildren > 0 && !isFullyChecked;
  };

  const isIndeterminate = checkIfIndeterminate(children);
  const shouldShowChildren = useMemo(() => {
    const isParentSelected = selectedTypes?.includes(parent) ?? false;
    const isChildSelected = children.some(
      (child) => selectedTypes?.includes(child)
    );
    return isParentSelected || isChildSelected;
  }, [selectedTypes]);

  const toggleParent = (type: ProcurementType) =>
    toggleFn(type, isIndeterminate);

  return (
    <li key={parent}>
      <Checkbox
        type={parent}
        total={typeItems?.find((item) => item.id === parent)?.total}
        toggleFn={toggleParent}
        isChecked={selectedTypes?.includes(parent)}
        isIndeterminate={isIndeterminate}
        isSearchPage={true}
        hasChildren={true}
        isParent={true}
      />
      {shouldShowChildren && children.length > 0 && (
        <ul>
          {children.map((child) => (
            <li key={child}>
              <Checkbox
                type={child}
                total={typeItems?.find((item) => item.id === child)?.total}
                toggleFn={toggleFn}
                isChecked={selectedTypes?.includes(child)}
                isSearchPage={true}
                hasChildren={true}
              />
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}
