import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import css from './InfoBox.module.scss';
import { Accordion } from 'components/accordion/Accordion';

export default function InfoBox(): ReactElement {
  const { t } = useTranslation('translations');

  const smBreakpoint = 768;
  const isSmBreakpoint = window.innerWidth < smBreakpoint;

  const [isSmallerScreen, setIsSmallerScreen] =
    useState<boolean>(isSmBreakpoint);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () =>
    setIsSmallerScreen(window.innerWidth < smBreakpoint);

  return (
    <>
      {isSmallerScreen ? (
        <Accordion summary={t('cpv.breadcrumb')}>
          {<p className={css.desc}>{t('cpv.info_desc')}</p>}
        </Accordion>
      ) : (
        <div className={css.infoBox_container} data-cy="info-box">
          <h2 className={css.title}>{t('cpv.breadcrumb')}</h2>
          <p>{t('cpv.info_desc')}</p>
        </div>
      )}
    </>
  );
}
