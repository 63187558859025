import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import css from './ResultCount.module.scss';
import Skeleton from 'components/skeleton/Skeleton';
import { formatNumbers } from 'shared/utils';

interface Props {
  readonly hitsPerPage: number | undefined;
  readonly totalHits: number | undefined;
  readonly isLoading: boolean;
}

export default function ResultCount({
  hitsPerPage,
  totalHits,
  isLoading
}: Props): ReactElement {
  const { t } = useTranslation('translations');
  const [searchParams] = useSearchParams();
  const pageFromUrl = searchParams.get('page');
  const selectedPage = pageFromUrl ? parseInt(pageFromUrl) : 1;
  const noticesPerPage = parseInt(searchParams.get('hitsPerPage') ?? '20');

  const getText = () => {
    if (totalHits === undefined && hitsPerPage === undefined) {
      return '';
    }

    const hitsPerPageAsNumber = hitsPerPage as number;
    const totalHitsAsNumber = totalHits as number;
    const formattedTotalHits = formatNumbers(totalHitsAsNumber.toString());

    const startIndex = (selectedPage - 1) * hitsPerPageAsNumber + 1;
    const endIndex = Math.min(
      selectedPage * hitsPerPageAsNumber,
      totalHitsAsNumber
    );
    const isLastPage =
      selectedPage === Math.ceil(totalHitsAsNumber / noticesPerPage);
    const remainingResults = totalHitsAsNumber - hitsPerPageAsNumber + 1;

    const shows = t('search.shows');
    const of = t('search.of');
    const results = t('search.result');

    if (totalHitsAsNumber <= hitsPerPageAsNumber) {
      return `${shows} ${formattedTotalHits} ${results}`;
    } else if (isLastPage) {
      return `${shows} ${remainingResults}-${formattedTotalHits} ${of} ${formattedTotalHits} ${results}`;
    } else {
      return `${shows} ${startIndex}-${endIndex} ${of} ${formattedTotalHits} ${results}`;
    }
  };

  return (
    <>
      {isLoading ? (
        <Skeleton style={{ height: '1.5rem', width: '10rem' }} />
      ) : (
        <div className={css.wrapper}>
          <p
            data-cy="result-count"
            className={css.result_text}
            aria-hidden={true}
          >
            {getText()}
          </p>
          <output className={css.aria_label}>
            {getText().replace('-', ` ${t('search.to')} `)}
          </output>
        </div>
      )}
    </>
  );
}
