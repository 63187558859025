import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExitRightIcon } from '../../../../images/icon/exit-right.svg';

import css from './MenuGroup.module.scss';
import { MenuItems } from '../MenuSelector';
import ExternalLink from 'components/externalLink/ExternalLink';

interface Props {
  readonly label: string;
  readonly links: MenuItems[];
  readonly handleMenuItemClick: () => void;
  readonly handleLastItemBlurred?: () => void;
}

export default function MenuGroup(props: Props): ReactElement {
  const { label, links, handleMenuItemClick, handleLastItemBlurred } = props;
  const { t } = useTranslation('translations');

  return (
    <div>
      <label className={css.group_label} lang="nb">
        {t(label)}
      </label>
      {links.map((item, index) => (
        <li key={item.text} className={css.list_item}>
          <ExternalLink
            href={item.link}
            role="menuitem"
            className={css.menu_item}
            onClick={handleMenuItemClick}
            onBlur={() => {
              if (handleLastItemBlurred && links.length === index + 1) {
                handleLastItemBlurred();
              }
            }}
          >
            {item.text}
            <ExitRightIcon />
          </ExternalLink>
        </li>
      ))}
    </div>
  );
}
