import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import css from '../datapage/Datapage.module.scss';
import DataDashboard from './elements/dataDashoard/DataDashboard';
import GraphView from './elements/graphView/GraphView';
import AboutData from './elements/aboutData/AboutData';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import FeedbackBox from './elements/feedbackBox/FeedbackBox';

export default function Datapage(): ReactElement {
  const { t } = useTranslation('translations');

  return (
    <div className={css.datapage}>
      <Breadcrumbs title={t('doffindata.title')} />
      <h1 className={css.title}>{t('doffindata.title')}</h1>
      <DataDashboard />
      <GraphView />
      <AboutData />
      <FeedbackBox
        email={t('feedbackbox.email')}
        subject={t('feedbackbox.subject')}
        body={t('feedbackbox.body')}
        title={t('feedbackbox.title')}
        description={t('feedbackbox.description')}
      />
    </div>
  );
}
