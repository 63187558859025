import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DissatisfiedIcon } from '../../../../images/icon/dissatisfied.svg';
import css from './ErrorState.module.scss';

export default function EmptyState(): ReactElement {
  const { t } = useTranslation('translations');

  return (
    <div className={css.wrapper} data-cy="error-message">
      <DissatisfiedIcon aria-hidden={true} />
      <h3>{t('error.search_title')}</h3>
      <p>{t('error.search')}</p>
    </div>
  );
}
