import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import css from './ImageLink.module.scss';
import ExternalLink from 'components/externalLink/ExternalLink';

interface Props {
  readonly title: string;
}

export default function VisualCard(props: Props): ReactElement {
  const { title } = props;
  const { t } = useTranslation('translations');

  return (
    <ExternalLink href="/data" className={css.card} target="">
      <div className={css.image_container}>
        <img
          aria-hidden={true}
          className={css.logo}
          src="/assets/images/styring.svg"
          alt={t('frontpage.search_img_alt_text')}
        />
      </div>
      <div className={css.tips_container}>
        <h2 aria-label={`${t(title)}: `}>{t(title)}</h2>
        <div className={css.content_arrow}>
          <p>{t('frontpage.search_tips_desc')}</p>
        </div>
      </div>
    </ExternalLink>
  );
}
