import React, { ReactElement } from 'react';
import { ReactComponent as ExitRightIcon } from '../../images/icon/exit-right.svg';
import { ReactComponent as WarningIcon } from '../../images/icon/warning.svg';

import css from './YellowLink.module.scss';
import ExternalLink from 'components/externalLink/ExternalLink';
import classnames from 'classnames';

interface Props {
  readonly title: string;
  readonly href: string;
  readonly text?: string;
  readonly isWarning?: boolean;
  readonly className?: string;
}

export default function YellowLink({
  title,
  href,
  text,
  isWarning,
  className
}: Props): ReactElement {
  return (
    <ExternalLink
      href={href}
      className={classnames(css.link, className, {
        [css.warning]: isWarning,
        [css.has_text]: !!text
      })}
    >
      {isWarning && <WarningIcon aria-hidden={true} />}
      <div className={css.textfield}>
        <b className={css.title}>{title}</b>
        {text && <p className={css.text}>{text}</p>}
      </div>
      <ExitRightIcon aria-hidden={true} />
    </ExternalLink>
  );
}
