import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import useCPV from 'shared/useCPV';
import css from './FactBox.module.scss';
import { OptionType } from 'models/cpvCode';
import { SEARCH } from 'shared-config';
import Skeleton from 'components/skeleton/Skeleton';

interface Props {
  readonly allCpvCodes: string[];
}

export default function CpvCodes({ allCpvCodes }: Props): ReactElement {
  const { t } = useTranslation('translations');
  const { cpvCodes, status } = useCPV();

  const getCpvCodes = (): OptionType[] => {
    if (!allCpvCodes.length || !cpvCodes) return [];

    const codes: OptionType[] = allCpvCodes
      .map((cpvCode) => cpvCodes.find((code) => code.value === cpvCode))
      .filter((codes) => codes !== undefined) as OptionType[];

    if (!codes.length) return [];

    return codes;
  };

  return (
    <div>
      <p className={css.cpv_label}>{t('notice.category')}</p>
      {status === 'loading' && (
        <Skeleton style={{ borderRadius: '22px', height: '2.25rem' }} />
      )}
      {status === 'error' && <p className={css.cpv_label}>Error</p>}
      {status === 'success' && (
        <>
          {getCpvCodes().length ? (
            <ul className={css.cpv_codes_wrapper} data-cy="cpv-codes">
              {getCpvCodes().map((code) => (
                <li key={code.value}>
                  <Link
                    to={`/${SEARCH}?cpvCodesLabel=${code.value}`}
                    aria-label={`${t('notice.category_link_label')}: ${
                      code.label
                    }`}
                    className={classnames(css.cpv_code, css.tooltip)}
                  >
                    <p lang="nb">{code.label}</p>
                    <span className={css.tooltip_text} role="tooltip" lang="nb">
                      {code.label}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <p>{t('common.not_specified')}</p>
          )}
        </>
      )}
    </div>
  );
}
