import React, { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ContractNatureType, ContractNatureItem } from 'models';
import Checkbox from 'components/checkbox/Checkbox';
import { isEqualLists } from 'shared/utils';
import { useSearchStringContext } from 'context/searchStringContext/SearchStringContext';

const ContractNatureLabels: Record<ContractNatureType, string> = {
  SERVICES: 'filter.contract_nature.services',
  SUPPLIES: 'filter.contract_nature.supplies',
  WORKS: 'filter.contract_nature.works'
};

interface Props {
  readonly contractNatureItems: ContractNatureItem[] | undefined;
}

export default function ContractNatureSelector({
  contractNatureItems
}: Props): ReactElement {
  const [selectedContractNature, setSelectedContractNature] = useState<
    ContractNatureType[]
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const contractNatureFromUrl = searchParams.get('contractNature');
  const { searchWord, isDesktopFilter } = useSearchStringContext();

  useEffect(() => {
    const contractNatureFromUrlList =
      (contractNatureFromUrl?.split(',') as ContractNatureType[]) ?? [];
    contractNatureFromUrlList &&
      setSelectedContractNature(contractNatureFromUrlList);
  }, [contractNatureFromUrl]);

  useEffect(() => {
    if (
      selectedContractNature.length &&
      shouldUpdateUrl(selectedContractNature)
    ) {
      searchParams.set('page', '1');
      searchParams.set('contractNature', selectedContractNature.join(','));
      if (searchWord !== '' && isDesktopFilter) {
        searchParams.set('searchString', searchWord);
      } else if (searchWord === '' && isDesktopFilter) {
        searchParams.delete('searchString');
      }
      setSearchParams(searchParams);
    }
  }, [selectedContractNature]);

  const shouldUpdateUrl = (selectedContractNature: string[]) => {
    const contractNature = contractNatureFromUrl?.split(',') ?? [];

    return !isEqualLists(contractNature, selectedContractNature);
  };

  const handleOnChange = (contractNature: ContractNatureType) => {
    const updatedSelection = new Set(selectedContractNature);

    updatedSelection.has(contractNature)
      ? updatedSelection.delete(contractNature)
      : updatedSelection.add(contractNature);

    if (!updatedSelection.size) {
      searchParams.set('page', '1');
      searchParams.delete('contractNature');
      if (searchWord !== '' && isDesktopFilter) {
        searchParams.set('searchString', searchWord);
      } else if (searchWord === '' && isDesktopFilter) {
        searchParams.delete('searchString');
      }
      setSearchParams(searchParams);
    }

    setSelectedContractNature([...updatedSelection]);
  };

  return (
    <>
      {Object.entries(ContractNatureLabels).map(([contractNature, label]) => {
        return (
          <Checkbox
            key={contractNature}
            type={label}
            isSearchPage={true}
            total={
              contractNatureItems?.find((item) => item.id === contractNature)
                ?.total
            }
            toggleFn={() =>
              handleOnChange(contractNature as ContractNatureType)
            }
            isChecked={selectedContractNature.includes(
              contractNature as ContractNatureType
            )}
          />
        );
      })}
    </>
  );
}
