import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import css from './NotFound.module.scss';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import { ReactComponent as ArrowRightIcon } from '../../images/icon/arrow-right.svg';
import { usePageTitleAndLanguageContext } from '../../context/pageTitleAndLanguageContext/PageTitleAndLanguageContext';

interface Props {
  isNotice?: boolean;
}

export const NotFound = ({ isNotice }: Props) => {
  const { t } = useTranslation('translations');
  const { setPageTitle } = usePageTitleAndLanguageContext();

  useEffect(() => {
    if (isNotice) {
      setPageTitle(t('error.notice'));
    } else {
      setPageTitle(t('error.header'));
    }
  }, [setPageTitle, t]);

  const getErrorTitle = () => {
    return isNotice ? t('error.notice') : t('error.header');
  };

  const getErrorText = () => {
    return isNotice ? t('error.notice_message') : t('error.message');
  };

  return (
    <>
      <Breadcrumbs title={isNotice ? t('error.notice') : t('error.header')} />
      <div className={css.not_found}>
        <img src={'/assets/images/not-found.svg'} alt={t('error.alt')} />
        <div className={css.content}>
          <h1 data-cy="error.title">{getErrorTitle()}</h1>
          <p>{getErrorText()}</p>
          <Link to="/" className={css.home_link} data-cy={t('error.back')}>
            {t('error.back')}
            <ArrowRightIcon />
          </Link>
        </div>
      </div>
    </>
  );
};
