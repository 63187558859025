import {
  ANSKAFFELSER_BRUKERVEILEDNING_URL,
  ANSKAFFELSER_OM_URL,
  ANSKAFFELSER_OPPDRAGSGIVERE_URL,
  ANSKAFFELSER_SEARCH_TIPS_URL,
  ANSKAFFELSER_TILBYDERE_URL,
  DFO_PERSONVERN_URL
} from 'shared-config';

interface Redirect {
  from: string;
  to: string;
}

export const isInternalUrl = (path: string) => path.startsWith('/');

export const redirects: Redirect[] = [
  { from: '/nn', to: '/' },
  { from: '/en', to: '/' },
  { from: '/authority', to: '/' },
  { from: '/nn/authority', to: '/' },
  { from: '/en/authority', to: '/' },
  {
    from: '/Eps.Localization/UserCulture/ChangeCulture?cultureName=en-GB',
    to: '/'
  },
  {
    from: '/Eps.Localization/UserCulture/ChangeCulture?cultureName=nb-NO',
    to: '/'
  },
  {
    from: '/Eps.Localization/UserCulture/ChangeCulture?cultureName=nn-NO',
    to: '/'
  },
  {
    from: '/Media/Default/Files/Behandling%20av%20opplysninger%20i%20Doffin%20(personvernerkl%C3%A6ring)%20III%20(1).docx',
    to: DFO_PERSONVERN_URL
  },
  {
    from: '/Media/Default/Files/Brukermanual%20leverand%C3%B8rer%20bokm%C3%A5l.pdf',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/Media/Default/Files/Brukermanual%20oppdragsgiver%20bm.pdf',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/Media/Default/Files/Doffin_leverand%C3%B8rer_2017.pdf',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/Media/Default/Files/Doffin_oppdragsgivere_2017.pdf',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/Media/Default/Files/Ofte_stilte_sp%C3%B8rsm%C3%A5l_oppdragsgivere.pdf',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/Notice',
    to: '/search'
  },
  {
    from: '/nn/Notice',
    to: '/search'
  },
  {
    from: '/en/Notice',
    to: '/search'
  },
  {
    from: '/Notice/Details/:id',
    to: '/notices/:id'
  },
  {
    from: '/nn/Notice/Details/:id',
    to: '/notices/:id'
  },
  {
    from: '/en/Notice/Details/:id',
    to: '/notices/:id'
  },
  {
    from: '/Help',
    to: ANSKAFFELSER_SEARCH_TIPS_URL
  },
  {
    from: '/nn/Help',
    to: ANSKAFFELSER_SEARCH_TIPS_URL
  },
  {
    from: '/en/Help',
    to: ANSKAFFELSER_SEARCH_TIPS_URL
  },
  {
    from: '/HelpDefinitions',
    to: ANSKAFFELSER_SEARCH_TIPS_URL
  },
  {
    from: '/nn/HelpDefinitions',
    to: ANSKAFFELSER_SEARCH_TIPS_URL
  },
  {
    from: '/en/HelpDefinitions',
    to: ANSKAFFELSER_SEARCH_TIPS_URL
  },
  {
    from: '/HelpLinks',
    to: ANSKAFFELSER_SEARCH_TIPS_URL
  },
  {
    from: '/nn/HelpLinks',
    to: ANSKAFFELSER_SEARCH_TIPS_URL
  },
  {
    from: '/en/HelpLinks',
    to: ANSKAFFELSER_SEARCH_TIPS_URL
  },
  {
    from: '/HelpSearch',
    to: ANSKAFFELSER_SEARCH_TIPS_URL
  },
  {
    from: '/nn/HelpSearch',
    to: ANSKAFFELSER_SEARCH_TIPS_URL
  },
  {
    from: '/en/HelpSearch',
    to: ANSKAFFELSER_SEARCH_TIPS_URL
  },
  {
    from: '/HelpSupport',
    to: ANSKAFFELSER_BRUKERVEILEDNING_URL
  },
  {
    from: '/nn/HelpSupport',
    to: ANSKAFFELSER_BRUKERVEILEDNING_URL
  },
  {
    from: '/en/HelpSupport',
    to: ANSKAFFELSER_BRUKERVEILEDNING_URL
  },
  {
    from: '/Home/About',
    to: ANSKAFFELSER_OM_URL
  },
  {
    from: '/nn/Home/About',
    to: ANSKAFFELSER_OM_URL
  },
  {
    from: '/en/Home/About',
    to: ANSKAFFELSER_OM_URL
  },
  {
    from: '/Home/Contact',
    to: ANSKAFFELSER_OM_URL
  },
  {
    from: '/nn/Home/Contact',
    to: ANSKAFFELSER_OM_URL
  },
  {
    from: '/en/Home/Contact',
    to: ANSKAFFELSER_OM_URL
  },
  {
    from: '/home/buyer',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/nn/home/buyer',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/en/home/buyer',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/home/buyer/login',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/nn/home/buyer/login',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/en/home/buyer/login',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/home/buyer/register',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/nn/home/buyer/register',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/en/home/buyer/register',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/home/buyer/TED',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/nn/home/buyer/TED',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/en/home/buyer/TED',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/Home/BuyerTermOfUse',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/nn/Home/BuyerTermOfUse',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/en/Home/BuyerTermOfUse',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/Home/TermOfUseSupplier',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/nn/Home/TermOfUseSupplier',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/en/Home/TermOfUseSupplier',
    to: ANSKAFFELSER_OPPDRAGSGIVERE_URL
  },
  {
    from: '/home/supplier',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/nn/home/supplier',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/en/home/supplier',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/home/supplierregister',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/nn/home/supplierregister',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/en/home/supplierregister',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/home/supplieruser',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/nn/home/supplieruser',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/en/home/supplieruser',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/home/suppliervarslingstjeneste',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/nn/home/suppliervarslingstjeneste',
    to: ANSKAFFELSER_TILBYDERE_URL
  },
  {
    from: '/en/home/suppliervarslingstjeneste',
    to: ANSKAFFELSER_TILBYDERE_URL
  }
];
