import React, { ReactElement } from 'react';
import css from './FilterError.module.scss';

interface Props {
  readonly errorMessage: string;
}
export default function FilterError({ errorMessage }: Props): ReactElement {
  return (
    <p className={css.error_message} data-cy="filter-error">
      <i role="alert" aria-atomic="true">
        {errorMessage}
      </i>
    </p>
  );
}
