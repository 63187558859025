import React from 'react';
import { useTranslation } from 'react-i18next';

import { buildUrl } from 'shared/http-common';
import { CPV } from 'shared-config';
import { ReactComponent as ListDocument } from '../../../images/icon/list-document.svg';
import css from '../HelpLinks.module.scss';

export default function CPVLink() {
  const { t } = useTranslation('translations');
  return (
    <a href={buildUrl([CPV])} className={css.link} data-cy="cpv-link">
      {t('cpv.link_label')}
      <ListDocument />
    </a>
  );
}
