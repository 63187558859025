import React, { ReactElement } from 'react';

import { Section } from 'models';
import EformSection from './EformSection';

interface Props {
  readonly sections: Section[] | undefined;
}
export default function EformSectionList({
  sections
}: Props): ReactElement | null {
  if (!sections) return null;

  return (
    <>
      {sections.map((section: Section) => {
        return (
          <EformSection
            section={section}
            key={`${section.sectionId}-${section?.value}`}
          />
        );
      })}
    </>
  );
}
