import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { SearchResponse } from 'models';
import { SEARCH } from 'shared-config';
import { searchParametersFromURL } from './search-common';
import { searchInstance } from 'shared/http-common';

const useSearch = () => {
  const [searchParams] = useSearchParams();

  const fetchSearch = async () => {
    return await searchInstance
      .post(`/${SEARCH}`, searchParametersFromURL(searchParams))
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  async function queryAndSetHistory(): Promise<SearchResponse> {
    const response = await fetchSearch();
    return response;
  }

  const { data, error, isLoading } = useQuery<SearchResponse, Error>(
    [searchParametersFromURL(searchParams)],
    queryAndSetHistory,
    { keepPreviousData: true }
  );

  return {
    data,
    error,
    isLoading,
    numHitsTotal: data?.numHitsTotal
  };
};

export default useSearch;
