import React, { ReactElement, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Location, CheckboxState, Locations } from 'models';
import css from './LocationItem.module.scss';
import { formatNumbers } from 'shared/utils';

interface Props {
  readonly location: Location;
  readonly toggleFn: (location: Location, isIndeterminate?: boolean) => void;
  readonly selectedLocations?: string[];
  readonly locationItems?: Locations[];
}

export default function LocationItem({
  location,
  selectedLocations,
  toggleFn,
  locationItems
}: Props): ReactElement {
  const [checkboxState, setCheckboxState] =
    useState<CheckboxState>('unchecked');
  const { t } = useTranslation('translations');

  const checkIfIndeterminate = (location: Location) => {
    const numberOfChildren = location.children.length;
    const checkedChildren = location.children.filter(
      (child) => selectedLocations?.includes(child.id)
    ).length;
    const isFullyChecked = numberOfChildren === checkedChildren;
    return checkedChildren > 0 && !isFullyChecked;
  };

  const isIndeterminate = checkIfIndeterminate(location);
  const isChecked = selectedLocations?.includes(location.id);

  useEffect(() => {
    if (isChecked && isIndeterminate) {
      setCheckboxState('indeterminate');
    } else if (isChecked) {
      setCheckboxState('checked');
    } else {
      setCheckboxState('unchecked');
    }
  }, [selectedLocations, isIndeterminate]);

  const handleCheckboxClick = (location: Location) => {
    toggleFn(location, isIndeterminate);

    checkboxState === 'unchecked' || checkboxState === 'indeterminate'
      ? setCheckboxState('checked')
      : setCheckboxState('unchecked');
  };

  const hasTotal = !!locationItems?.find((item) => item.id === location.id)
    ?.total;

  const total =
    locationItems?.find((item) => item.id === location.id)?.total ?? 0;

  const formatLabel = `${location.name} (${formatNumbers(total.toString())})`;

  const accessibilityLabel = `${location.name} (${formatNumbers(
    total.toString()
  )}${t('search.result')})`;

  const isCheckboxClickable =
    !total && checkboxState !== 'checked' && checkboxState !== 'indeterminate';

  return (
    <li key={location.id}>
      <label
        className={classnames(
          css.checkbox_label,
          !hasTotal && isCheckboxClickable && css.no_hits
        )}
        lang="nb"
        data-cy={location.id}
      >
        <input
          type="checkbox"
          checked={checkboxState === 'checked'}
          onChange={() => handleCheckboxClick(location)}
          className={classnames(
            css.checkbox,
            !hasTotal && isCheckboxClickable ? css.checkbox_disabled : ''
          )}
          name={location.name}
          aria-label={accessibilityLabel}
          id={location.id}
          data-cy={location.id}
          ref={(input) =>
            input && (input.indeterminate = checkboxState === 'indeterminate')
          }
        />
        {formatLabel}
      </label>
      {(isChecked || isIndeterminate) && location.children.length > 0 && (
        <ul>
          {location.children.map((l) => (
            <LocationItem
              location={l}
              selectedLocations={selectedLocations}
              toggleFn={toggleFn}
              key={l.id}
              locationItems={locationItems}
            />
          ))}
        </ul>
      )}
    </li>
  );
}
