import React, { createContext, useContext, useMemo, useState } from 'react';

type setSearchWord = (value: string) => void;
type setIsDesktopFilter = (filterType: boolean) => void;

export const SearchStringContext = createContext<
  | undefined
  | {
      searchWord: string;
      setSearchWord: setSearchWord;
      isDesktopFilter: boolean;
      setIsDesktopFilter: setIsDesktopFilter;
    }
>(undefined);

export const UpdateSearchWord = ({ children }: { children }) => {
  const [searchWord, setSearchWord] = useState<string>('');
  const [isDesktopFilter, setIsDesktopFilter] = useState<boolean>(true);

  const contextValue = useMemo(() => {
    const lgBreakpoint = 1180;
    const isLgBreakpoint = window.innerWidth < lgBreakpoint;

    if (isLgBreakpoint) {
      setIsDesktopFilter(false);
    }
    return { searchWord, setSearchWord, isDesktopFilter, setIsDesktopFilter };
  }, [searchWord, isDesktopFilter]);

  return (
    <SearchStringContext.Provider value={contextValue}>
      {children}
    </SearchStringContext.Provider>
  );
};

export const useSearchStringContext = () => {
  const context = useContext(SearchStringContext);
  if (context === undefined) {
    throw new Error(
      'useSearchStringContext must be used within a SearchStringContext'
    );
  }
  return context;
};
