import React, { ReactElement, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import css from '../dataviewpage/dataviewpage.module.scss';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import getEmbedToken from 'shared/useData';
import { graphViewList } from 'shared-config';

export default function Dataviewpage(): ReactElement {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('translations');
  const graphName = searchParams.get('graph');
  const reportContainer = useRef(null);
  let title = '';
  let reportId = '';

  useEffect(() => {
    scrollTo({ top: 0 });
  }, []);

  switch (
    graphName
      ?.replaceAll('_', ' ')
      .replace(graphName?.charAt(0), graphName.charAt(0).toUpperCase())
  ) {
    case t('doffindata.graphcard.title_card1', { lng: 'en' }):
      title = 'doffindata.graphcard.title_card1';
      reportId = graphViewList[0].reportId;
      break;
    case t('doffindata.graphcard.title_card2', { lng: 'en' }):
      title = 'doffindata.graphcard.title_card2';
      reportId = graphViewList[1].reportId;
      break;
    case t('doffindata.graphcard.title_card3', { lng: 'en' }):
      title = 'doffindata.graphcard.title_card3';
      reportId = graphViewList[2].reportId;
      break;
    case t('doffindata.graphcard.title_card4', { lng: 'en' }):
      title = 'doffindata.graphcard.title_card4';
      reportId = graphViewList[3].reportId;
      break;
    default:
      title = 'app.title';
  }
  getEmbedToken(reportId, reportContainer);

  return (
    <div className={css.dataviewpage}>
      <Breadcrumbs title={t(title)} />
      <h1 className={css.title}>{t(title)}</h1>
      <div ref={reportContainer} className={css.report}></div>
    </div>
  );
}
