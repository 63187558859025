import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as ArrowRightIcon } from '../../../../images/icon/arrow-right.svg';
import css from './SearchCard.module.scss';
import { Procurement, ProcurementType } from 'models';
import { SEARCH } from 'shared-config';

interface Props {
  readonly title: string;
  readonly type: ProcurementType;
  readonly isProcurementTitle?: boolean;
}

export default function SearchCard(props: Props): ReactElement {
  const { title, type, isProcurementTitle } = props;
  const { t } = useTranslation('translations');

  const getContentText = () => {
    switch (title) {
      case 'frontpage.conclusion_contract_title':
        return t('frontpage.conclusion_contract_desc');
      case Procurement.DYNAMIC_PURCHASING_SCHEME:
        return t('frontpage.dps_desc');
    }
  };
  return (
    <Link
      to={`/${SEARCH}?&type=${type}`}
      className={classNames(
        css.search_card,
        title === Procurement.DYNAMIC_PURCHASING_SCHEME && css.light_blue
      )}
      data-cy="link-element"
    >
      <div className={css.search_content}>
        <h2 aria-label={`${t(title.toLowerCase())}:`}>
          {isProcurementTitle
            ? t('subtype.' + title.toLowerCase())
            : t(title.toLowerCase())}
        </h2>
        <p data-cy="search-card-content">{getContentText()}</p>
      </div>
      <ArrowRightIcon />
    </Link>
  );
}
