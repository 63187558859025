import React, { ReactElement } from 'react';
import classnames from 'classnames';

import css from './MultiSection.module.scss';

interface Props {
  readonly h3: string;
  readonly h4: string;
  readonly content?: string | string[] | null;
}

export default function FullSection({
  h3,
  h4,
  content
}: Props): ReactElement | null {
  if (!content) return null;

  const renderContent = (content: string | string[]) => {
    if (typeof content === 'string') {
      return <p>{content}</p>;
    }
  };

  return (
    <section className={css.full_section}>
      {h3 && <h3>{h3}</h3>}
      {h4 && <h4>{h4}</h4>}
      {content && !!content.length && (
        <div className={classnames(css.content_container, css.full)}>
          <section className={css.content_container__section_left}>
            {renderContent(content)}
          </section>
        </div>
      )}
    </section>
  );
}
