import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SearchTypes } from 'models';
import css from './SearchSelector.module.scss';
import classNames from 'classnames';

export interface SearchSelectorProps {
  readonly focusedIndex: number;
  readonly searchCheckboxes: Array<SearchTypes>;
}

export default function SearchSelector(
  props: SearchSelectorProps
): ReactElement {
  const { focusedIndex, searchCheckboxes } = props;
  const { t } = useTranslation('translations');

  return (
    <fieldset className={css.search_filter} id="search-selector">
      <div className={css.linkbox}>
        <legend className={css.search_text} aria-hidden="true">
          <h2>{t('frontpage.search_title')}</h2>
        </legend>
        <div className={css.linklist}>
          {searchCheckboxes.map((element: SearchTypes) => {
            const tolink =
              element.type === 'COMPETITION'
                ? `/search?page=1&type=${element.type}&status=ACTIVE`
                : `/search?page=1&type=${element.type}`;
            let isFocused = false;
            switch (element.type) {
              case 'PLANNING':
                isFocused = focusedIndex === 1;
                break;
              case 'COMPETITION':
                isFocused = focusedIndex === 2;
                break;
              case 'RESULT':
                isFocused = focusedIndex === 3;
                break;
            }

            return (
              <Link
                to={tolink}
                key={element.type}
                className={classNames(css.link, isFocused ? css.linkfocus : '')}
                id={`select_option_${focusedIndex}`}
                aria-selected={isFocused}
                aria-label={
                  t('frontpage.search_title') + ': ' + t(element.label)
                }
              >
                <p aria-hidden="true">{t(element.label)}</p>
              </Link>
            );
          })}
        </div>
      </div>
    </fieldset>
  );
}
