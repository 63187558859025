import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import css from './HitsPerPageSelector.module.scss';
import { useSearchParams } from 'react-router-dom';
import { HitsPerPageType, HitsPerPage } from 'models';

export default function HitsPerPageSelector(): ReactElement {
  const { t } = useTranslation('translations');
  const [searchParams, setSearchParams] = useSearchParams();
  const hitsPerPageFromUrl = searchParams.get('hitsPerPage');

  const selectedOption = (): HitsPerPageType => {
    const selectedValue = hitsPerPageFromUrl as HitsPerPageType | null;
    return selectedValue ?? 20;
  };

  const handleSelect = (value: string) => {
    searchParams.set('hitsPerPage', value);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  return (
    <div className={css.select_container}>
      <select
        onChange={(selected) => handleSelect(selected.target.value)}
        value={selectedOption()}
        className={css.select}
        aria-label={t('search.results_per_page')}
        id="hits_select"
        data-cy="hits_select"
      >
        {Object.values(HitsPerPage).map((hitsPerPageValue) => (
          <option key={hitsPerPageValue} value={hitsPerPageValue}>
            {t('hits.' + hitsPerPageValue.toString())}
          </option>
        ))}
      </select>
      <div className={css.icon} aria-hidden={true} />
    </div>
  );
}
