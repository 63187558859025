import React, {
  ReactElement,
  ReactNode,
  useState,
  MouseEvent,
  useEffect
} from 'react';

import { ReactComponent as ChevronDownIcon } from '../../images/icon/chevron-down.svg';
import css from './Accordion.module.scss';

interface Props {
  readonly summary: ReactNode | string;
  readonly isExpanded?: boolean;
  readonly children?: ReactNode;
}

export function Accordion({
  summary,
  isExpanded,
  children
}: Props): ReactElement {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(isExpanded);

  useEffect(() => {
    setIsAccordionExpanded(isExpanded);
  }, [isExpanded]);

  const handleSummaryClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setIsAccordionExpanded(!isAccordionExpanded);
  };

  return (
    <details open={isAccordionExpanded} className={css.accordion}>
      <summary
        onClick={handleSummaryClick}
        onKeyDown={(event) =>
          event.key === 'Space' && setIsAccordionExpanded(!isAccordionExpanded)
        }
      >
        <h2 className={css.title}>{summary}</h2>
        <ChevronDownIcon />
      </summary>
      <div className={css.content}>{children}</div>
    </details>
  );
}
