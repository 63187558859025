import React, { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Notice } from 'models';
import css from './FactBox.module.scss';
import { formatDate, formatDateAndTime } from 'shared/utils';
import { ReactComponent as ClockIcon } from '../../../../images/icon/alarm-clock.svg';

interface BaseCardProps {
  readonly label: string;
  readonly value: string;
  readonly className?: string;
  readonly icon?: ReactNode;
  readonly extraInfo?: string;
  readonly dataCy?: string;
}
const BaseCard = ({
  label,
  value,
  extraInfo,
  className,
  icon,
  dataCy
}: BaseCardProps) => (
  <div className={classnames(css.status_card, className)} data-cy={dataCy}>
    <div className={css.label_wrapper}>
      {icon}
      <p>{label}</p>
    </div>
    <p className={css.value_wrapper}>{value}</p>
    {extraInfo && <p className={css.extra_info}>{extraInfo}</p>}
  </div>
);

interface Props {
  readonly notice: Notice;
}

export default function InfoCard({ notice }: Props): ReactElement {
  const { t } = useTranslation('translations');

  const { noticeStatus, noticeType, deadline, receivedTenders, awardedNames } =
    notice;

  const getOfferDeadline = () => {
    if (!deadline) return;

    return formatDateAndTime(deadline);
  };

  const getAwardedContractors = () => {
    if (!awardedNames?.length) return t('common.not_specified');

    const contractors = awardedNames?.filter((name) => name !== undefined);

    if (!contractors.length) return t('common.not_specified');

    return contractors?.join(', ');
  };

  const getInfoCardContent = () => {
    const status = awardedNames?.length ? 'AWARDED' : noticeStatus;

    switch (status) {
      case 'ACTIVE': {
        if (noticeType === 'DYNAMIC_PURCHASING_SCHEME') {
          return (
            <BaseCard
              label={t('notice.status_open_dps')}
              value={
                deadline ? formatDate(deadline) : t('common.not_specified')
              }
            />
          );
        } else {
          return (
            <BaseCard
              className={css.active_deadline}
              label={t('notice.status_open')}
              value={getOfferDeadline() ?? ''}
              icon={<ClockIcon className={css.icon} aria-hidden={true} />}
            />
          );
        }
      }
      case 'AWARDED':
        if (noticeType == 'ANNOUNCEMENT_OF_COMPETITION') {
          return null;
        } else {
          return (
            <BaseCard
              className={css.awarded}
              dataCy="awarded"
              label={t('notice.status_awarded_to')}
              value={getAwardedContractors()}
              extraInfo={`${t('search.card.num_providers')}: ${
                receivedTenders ?? t('common.not_specified')
              }`}
            />
          );
        }
    }
  };

  return <>{getInfoCardContent()}</>;
}
