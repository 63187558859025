import React, { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import css from './Pagination.module.scss';

interface Props {
  readonly label: string;
  readonly children: number | ReactNode;
  readonly onClick: () => void;
  readonly selected?: boolean;
  readonly disabled?: boolean;
}

export default function PaginationButton(props: Props): ReactElement {
  const { onClick, label, selected, disabled, children } = props;
  const { t } = useTranslation('translations');

  return (
    <li>
      <button
        aria-label={`${label} ${selected ? t('active') : ''}`}
        data-cy={label}
        className={classnames(css.nav_button, { [css.selected]: selected })}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    </li>
  );
}
