import React, { ReactElement } from 'react';

import css from '../../../../components/skeleton/Skeleton.module.scss';
import Skeleton from 'components/skeleton/Skeleton';

interface Props {
  readonly isMobile: boolean;
}

export default function CpvTreeSkeleton({ isMobile }: Props): ReactElement {
  return (
    <div>
      {!isMobile && (
        <div
          style={{ display: 'flex', gap: '1rem', marginBottom: '1.5rem' }}
          className={css.loading}
        >
          <div
            style={{ height: '3.75rem', width: '9.4rem' }}
            className={css.skeleton}
          />
          <div
            style={{ height: '3.75rem', width: '9.4rem' }}
            className={css.skeleton}
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: isMobile ? '1.5rem' : '0px'
        }}
      >
        <Skeleton style={{ height: '1.5rem', width: '85%' }} />
        <Skeleton style={{ height: '1.5rem', width: '80%' }} />
        <Skeleton style={{ height: '1.5rem', width: '75%' }} />
        <Skeleton style={{ height: '1.5rem', width: '80%' }} />
        <Skeleton style={{ height: '1.5rem', width: '40%' }} />
        <Skeleton style={{ height: '1.5rem', width: '55%' }} />
        <Skeleton style={{ height: '1.5rem', width: '65%' }} />
        <Skeleton style={{ height: '1.5rem', width: '40%' }} />
        <Skeleton style={{ height: '1.5rem', width: '35%' }} />
        <Skeleton style={{ height: '1.5rem', width: '90%' }} />
        <Skeleton style={{ height: '1.5rem', width: '80%' }} />
        <Skeleton style={{ height: '1.5rem', width: '75%' }} />
      </div>
    </div>
  );
}
