import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import css from './StatusBar.module.scss';
import { formatDate } from 'shared/utils';
import { Notice } from 'models';

interface Props {
  readonly notice: Notice;
}

export default function StatusBar({ notice }: Props): ReactElement | null {
  const { t } = useTranslation('translations');
  const { publicationDate, noticeStatus, allTypes, noticeType, issueDate } =
    notice;
  const isTypeGeneralNotice = allTypes?.includes('COMPETITION');

  const styleStatus = classNames({
    [css.open]: noticeStatus === 'ACTIVE',
    [css.awarded]: noticeStatus === 'AWARDED',
    [css.expired]: noticeStatus === 'EXPIRED',
    [css.cancelled]: noticeStatus === 'CANCELLED',
    [css.defaultStyle]: !noticeStatus
  });

  const getStatus = () => {
    switch (noticeStatus) {
      case 'ACTIVE':
        return (
          <span className={css.StatusBarActive}>
            {t('filter.status.active')}
          </span>
        );
      case 'AWARDED':
        return (
          <span className={css.StatusBarAwarded}>
            {t('filter.status.awarded')}
          </span>
        );
      case 'EXPIRED':
        return (
          <span className={css.StatusBarExpired}>
            {t('filter.status.expired')}
          </span>
        );
      case 'CANCELLED':
        return (
          <span className={css.StatusBarCancelled}>
            {t('filter.status.cancelled')}
          </span>
        );
    }
  };

  return (
    <>
      {noticeType || publicationDate || issueDate ? (
        <div
          className={classNames(
            css.StatusBar,
            isTypeGeneralNotice ? styleStatus : css.defaultStyle
          )}
          data-cy="statusBar"
        >
          <h3 className={classNames(css.statusHeader)}>
            {isTypeGeneralNotice && getStatus()}
          </h3>
          <div className={css.announcementWrap}>
            <span className={css.announcementType}>
              {t(('subtype.' + noticeType || '').toLowerCase())}
            </span>
            {publicationDate && (
              <span className={css.announcementDate}>
                {`${t('filter.date')}: ${formatDate(publicationDate)}`}
              </span>
            )}
            {!publicationDate && issueDate && (
              <span className={css.announcementDate}>
                {`${t('filter.date')}: ${formatDate(issueDate)}`}
              </span>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}
