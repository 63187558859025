import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExitRightIcon } from '../../../../images/icon/exit-right.svg';

import css from './KGVBox.module.scss';
import ExternalLink from 'components/externalLink/ExternalLink';

interface Props {
  readonly url?: string;
}

export default function KGVBox({ url }: Props): ReactElement {
  const { t } = useTranslation('translations');

  return (
    <div className={css.content} data-cy="kgv-box">
      <h2 className={css.title}>{t('notice.kgv_documents')}</h2>
      <p className={css.description}>
        {url ? t('notice.kgv_desc') : t('notice.kgv_not_found')}
      </p>
      {url && (
        <ExternalLink className={css.btn} href={url}>
          {t('notice.kgv_button_text')}
          <ExitRightIcon />
        </ExternalLink>
      )}
    </div>
  );
}
