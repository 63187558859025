import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as ChevronLeftIcon } from '../../../../images/icon/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../../../images/icon/chevron-right.svg';
import PaginationButton from './PaginationButton';
import css from './Pagination.module.scss';
import { HitsPerPageType } from 'models';

interface Props {
  readonly numHitsTotal: number | undefined;
  readonly isLoading: boolean;
  readonly error: Error | null;
}

export default function Pagination(props: Props): ReactElement {
  const { t } = useTranslation('translations');
  const { numHitsTotal, isLoading, error } = props;

  const [numHitsTotalSaved, setNumHitsTotalSaved] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromUrl = searchParams.get('page');
  const hitsPerPageFromUrl = searchParams.get('hitsPerPage');
  const selectedPage = pageFromUrl ? parseInt(pageFromUrl) : 1;
  const firstRender = useRef(true);

  const numHitsPerPage = (): HitsPerPageType => {
    const selectedValue = hitsPerPageFromUrl as HitsPerPageType | null;
    return selectedValue ?? 20;
  };

  const smBreakpoint = 568;
  const isSmBreakpoint = window.innerWidth < smBreakpoint;
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(isSmBreakpoint);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (selectedPage > getNumberOfPages()) {
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, [selectedPage, isLoading]);

  const handleResize = () => setIsSmallScreen(window.innerWidth < smBreakpoint);

  const getNumberOfPages = () => {
    const numHitsToUseInCalculation = numHitsTotal ?? numHitsTotalSaved;
    return Math.ceil(numHitsToUseInCalculation / numHitsPerPage());
  };

  const generatePageNumbers = (): number[] => {
    const pagesToShow = isSmallScreen ? 3 : 7;
    const pageNumbers: number[] = [];
    const halfShowPages = Math.floor(pagesToShow / 2);
    let startPage = Math.max(1, selectedPage - halfShowPages);
    const endPage = Math.min(getNumberOfPages(), startPage + pagesToShow - 1);

    if (endPage - startPage + 1 < pagesToShow) {
      startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const scrollWindowToTop = () => window.scrollTo({ top: 0 });

  const focusTopOfPage = () => document.getElementById('notice-link')?.focus();

  const handleOnPageChange = (pageNumber: number) => {
    numHitsTotal && setNumHitsTotalSaved(numHitsTotal);
    searchParams.set('page', pageNumber.toString());
    setSearchParams(searchParams);
    scrollWindowToTop();
    focusTopOfPage();
  };

  return (
    <>
      {(isLoading && selectedPage === 1) ||
      (!isLoading && numHitsTotal === 0) ||
      error ? (
        <div className={css.placeholder}></div>
      ) : (
        <nav className={css.nav} aria-label={t('search.pagination')}>
          <ul className={css.list_wrapper}>
            <PaginationButton
              onClick={() => handleOnPageChange(selectedPage - 1)}
              disabled={selectedPage <= 1}
              label={t('pagination.previous_page')}
            >
              <ChevronLeftIcon />
            </PaginationButton>
            {generatePageNumbers().map((pageNumber) => (
              <PaginationButton
                key={pageNumber}
                onClick={() => handleOnPageChange(pageNumber)}
                label={t('pagination.index_page', { index: pageNumber })}
                selected={pageNumber === selectedPage}
              >
                {pageNumber}
              </PaginationButton>
            ))}
            <PaginationButton
              onClick={() => handleOnPageChange(selectedPage + 1)}
              label={t('pagination.next_page')}
              disabled={selectedPage >= getNumberOfPages()}
            >
              <ChevronRightIcon />
            </PaginationButton>
          </ul>
        </nav>
      )}
    </>
  );
}
