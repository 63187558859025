import React, { createContext, useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

type setLanguage = (title: string) => void;
type SetPageTitle = (title: string) => void;

export const PageTitleAndLanguageContext = createContext<
  | {
      pageTitle: string;
      setPageTitle: SetPageTitle;
      setLanguage: setLanguage;
      language: string;
    }
  | undefined
>(undefined);

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const PageHeadProvider = ({ children }: { children }) => {
  const { t } = useTranslation('translations');
  const appTitle = `${t('app.title')}, ${t('app.subtitle')}`;

  const [pageTitle, setPageTitle] = useState<string>('');
  const [language, setLanguage] = useState<string>(
    localStorage.getItem('locale') ?? 'nb'
  );

  const value = useMemo(() => {
    document.documentElement.lang = language;

    return {
      pageTitle,
      setPageTitle,
      language,
      setLanguage
    };
  }, [pageTitle, setPageTitle, language, setLanguage]);

  return (
    <PageTitleAndLanguageContext.Provider value={value}>
      <Helmet
        titleTemplate={`%s | ${appTitle}`}
        defaultTitle={appTitle}
        htmlAttributes={{ lang: language }}
      >
        {!!pageTitle && <title>{pageTitle}</title>}
        <meta
          property="og:title"
          content={pageTitle ? `${pageTitle} | ${appTitle}` : appTitle}
        />
      </Helmet>
      {children}
    </PageTitleAndLanguageContext.Provider>
  );
};

export const usePageTitleAndLanguageContext = () => {
  const context = useContext(PageTitleAndLanguageContext);
  if (context === undefined) {
    throw new Error(
      'usePageTitleAndLanguageContext must be used within a PageTitleAndLanguageProvider'
    );
  }
  return context;
};
