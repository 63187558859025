import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import css from './Frontpage.module.scss';
import { SEARCH } from '../../shared-config';
import { usePageTitleAndLanguageContext } from '../../context/pageTitleAndLanguageContext/PageTitleAndLanguageContext';
import VisualCard from './elements/imageLink/ImageLink';
import SearchCard from './elements/searchCard/SearchCard';
import { Procurement, Status } from 'models';
import LinkList from './elements/linkList/LinkList';
import AutoSuggest from 'components/autoSuggest/AutoSuggest';
import HelpLinks from 'components/helpLinks/HelpLinks';
import { UpdateSearchWord } from '../../context/searchStringContext/SearchStringContext';

export default function Frontpage(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation('translations');
  const { setPageTitle } = usePageTitleAndLanguageContext();
  let selectedCheckboxes: string = '';

  useEffect(() => {
    setPageTitle('');
  }, [setPageTitle, t]);

  const searchDoffin = (queryValue: string, categoryName?: string) => {
    const searchString =
      queryValue !== '' && !categoryName ? `searchString=${queryValue}` : '';
    const selectedTypes = selectedCheckboxes.length
      ? `&type=${selectedCheckboxes}`
      : '';
    const status =
      selectedCheckboxes === Procurement.COMPETITION
        ? `&status=${Status.ACTIVE}`
        : '';

    const category = categoryName ? `&${categoryName}=${queryValue}` : '';
    navigate(`/${SEARCH}?${searchString}${category}${selectedTypes}${status}`);
  };

  const updateFrontPageFilters = (value: string) => {
    selectedCheckboxes = value;
  };

  return (
    <UpdateSearchWord>
      <div className={css.frontpage}>
        <div className={css.top_section}>
          <section>
            <h1 className={css.title}>{t('frontpage.title')}</h1>
            <div className={css.search_container}>
              <HelpLinks isFrontPage={true} />
              <AutoSuggest
                placeholder={t('search.placeholder')}
                searchFromFrontPage={searchDoffin}
                isFrontPage={true}
                updateFrontPageFilters={updateFrontPageFilters}
              />
            </div>
          </section>
        </div>
        <div className={css.bottom_section}>
          <VisualCard title="frontpage.search_tips" />
          <section className={css.search_cards}>
            <SearchCard
              title="frontpage.conclusion_contract_title"
              type={Procurement.ANNOUNCEMENT_OF_CONCLUSION_OF_CONTRACT}
            />
            <SearchCard
              title={Procurement.DYNAMIC_PURCHASING_SCHEME}
              type={Procurement.DYNAMIC_PURCHASING_SCHEME}
              isProcurementTitle={true}
            />
          </section>
          <LinkList />
        </div>
      </div>
    </UpdateSearchWord>
  );
}
