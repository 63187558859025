import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Notice, Organization } from 'models';
import css from './FactBox.module.scss';
import InfoCard from 'pages/noticepage/elements/factBox/InfoCard';
import Table from 'pages/noticepage/elements/factBox/Table';
import ExternalLink from 'components/externalLink/ExternalLink';
import { ReactComponent as ExitRightIcon } from '../../../../images/icon/exit-right.svg';
import CpvCodes from 'pages/noticepage/elements/factBox/CpvCodes';

interface Props {
  readonly notice: Notice;
}

export default function FactBox({ notice }: Props): ReactElement {
  const { t } = useTranslation('translations');

  const {
    noticeStatus,
    buyer,
    awardedNames,
    regulationUrl,
    allCpvCodes,
    allTypes,
    tedId,
    deadline
  } = notice;

  let tedUrl = '';
  const isResultNotice = allTypes?.includes('RESULT');
  const isCompetitionNotice = allTypes?.includes('COMPETITION');
  const hasDeadline = deadline !== null;
  const shouldShowInfoCard = (): boolean => {
    if (!noticeStatus && !awardedNames) return false;

    return (
      (noticeStatus === 'ACTIVE' && isCompetitionNotice && hasDeadline) ||
      (noticeStatus === 'AWARDED' && isResultNotice) ||
      !!awardedNames?.length
    );
  };

  const getBuyers = () => {
    const buyers = buyer?.filter((buyer) => buyer !== undefined);

    if (!buyers?.length) return t('common.not_specified');

    return (buyers as Organization[]).map((buyer) => buyer.name).join(', ');
  };

  if (tedId) {
    tedUrl = 'https://ted.europa.eu/en/notice/-/detail/' + tedId;
  }

  return (
    <div className={css.box} data-cy="factbox">
      {!!buyer?.length && <h2 className={css.contractor}>{getBuyers()}</h2>}
      {shouldShowInfoCard() && <InfoCard notice={notice} />}
      <Table notice={notice} />
      <div className={css.link_box}>
        {regulationUrl && (
          <ExternalLink
            className={css.link}
            data-cy={t('notice.procurement_regulations')}
            href={regulationUrl}
          >
            {t('notice.legal_basis')}
            <ExitRightIcon />
          </ExternalLink>
        )}
        {tedId && (
          <ExternalLink
            className={classnames(css.link, css.link_ted)}
            data-cy={t('notice.procurement_regulations')}
            href={tedUrl}
          >
            {t('notice.ted_id')}
            <ExitRightIcon />
          </ExternalLink>
        )}
      </div>

      {allCpvCodes && <CpvCodes allCpvCodes={allCpvCodes} />}
    </div>
  );
}
