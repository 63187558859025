import React, { ReactElement } from 'react';
import classnames from 'classnames';

import Skeleton from 'components/skeleton/Skeleton';
import css from 'components/skeleton/Skeleton.module.scss';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import cssNoticePage from './Noticepage.module.scss';

const StatusBar = () => (
  <div className={classnames(css.loading, cssNoticePage.skeleton_status_bar)}>
    <div />
    <span
      style={{
        height: '3.5rem'
      }}
      className={css.skeleton}
    />
  </div>
);

const OriginChip = () => <Skeleton className={cssNoticePage.skeleton_origin} />;

const Heading = () => <Skeleton style={{ height: '5rem' }} />;

const Description = () => (
  <Skeleton
    className={cssNoticePage.skeleton_description}
    style={{ height: '11.5rem', display: 'block' }}
  />
);

const Accordions = () => (
  <Skeleton
    className={cssNoticePage.skeleton_accordion}
    style={{ height: '11.5rem', display: 'block' }}
  />
);

const CpvCode = ({ width }: { width: string }) => (
  <div
    className={css.skeleton}
    style={{ borderRadius: '22px', height: '2.25rem', width }}
  />
);

const FactBox = () => (
  <div className={cssNoticePage.skeleton_fact_box}>
    <Skeleton style={{ height: '1.875rem', width: '10rem' }} />
    <div
      style={{
        backgroundColor: 'var(--tab-button-bg-hover)',
        height: '8rem',
        width: '100%'
      }}
      className={css.loading}
    ></div>
    <Skeleton style={{ height: '1.25rem' }} />
    <Skeleton style={{ height: '1.25rem' }} />
    <Skeleton style={{ height: '1.25rem' }} />
    <Skeleton style={{ height: '2.875rem' }} />
    <div
      style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}
      className={css.loading}
    >
      <div style={{ width: '100%' }}>
        <Skeleton style={{ height: '1.5rem', width: '35%' }} />
      </div>
      <CpvCode width="8.5rem" />
      <CpvCode width="11.5rem" />
      <CpvCode width="11.5rem" />
      <CpvCode width="8.5rem" />
    </div>
  </div>
);

const KgvBox = () => (
  <div className={cssNoticePage.skeleton_kgv}>
    <div
      style={{
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem'
      }}
    >
      <Skeleton style={{ height: '1.75rem' }} />
      <Skeleton style={{ height: '2.75rem' }} />
      <div
        style={{
          backgroundColor: 'var(--tab-button-bg-hover)',
          height: '3.375rem',
          width: 'inherit'
        }}
        className={css.loading}
      ></div>
    </div>
  </div>
);

const ProcurementTimeline = () => (
  <div className={cssNoticePage.skeleton_procurement_timeline}>
    <Skeleton
      style={{ height: '1.5rem', width: '60%', marginBottom: '1rem' }}
    />
    <Skeleton
      style={{ height: '1rem', width: '80%', marginBottom: '0.5rem' }}
    />
    <Skeleton style={{ height: '1rem', width: '80%' }} />
  </div>
);

export default function NoticePageSkeleton(): ReactElement {
  return (
    <div className={cssNoticePage.notice_page_skeleton}>
      <Breadcrumbs
        title={<Skeleton className={cssNoticePage.skeleton_breadcrumb} />}
      />
      <StatusBar />
      <OriginChip />
      <Heading />
      <div className={cssNoticePage.skeleton_main}>
        <Description />
        <Accordions />
        <FactBox />
        <KgvBox />
        <ProcurementTimeline /> {/* Added ProcurementTimeline as last item */}
      </div>
    </div>
  );
}
