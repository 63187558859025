import React, { CSSProperties, ReactElement } from 'react';
import classnames from 'classnames';

import css from './Skeleton.module.scss';

interface Props {
  readonly style?: CSSProperties;
  readonly className?: string;
}

export default function Skeleton({ style, className }: Props): ReactElement {
  return (
    <span
      data-cy="loading-skeleton"
      style={style}
      className={classnames(css.skeleton, css.loading, className)}
    ></span>
  );
}
