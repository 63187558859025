import React, { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { StatusItem, StatusType } from 'models';
import Checkbox from 'components/checkbox/Checkbox';
import { isEqualLists } from 'shared/utils';
import { useSearchStringContext } from 'context/searchStringContext/SearchStringContext';

const StatusLabels: Record<StatusType, string> = {
  ACTIVE: 'filter.status.active',
  EXPIRED: 'filter.status.expired',
  AWARDED: 'filter.status.awarded',
  CANCELLED: 'filter.status.cancelled'
};

interface Props {
  readonly statusItems: StatusItem[] | undefined;
}

export default function StatusSelector({ statusItems }: Props): ReactElement {
  const [selectedStatus, setSelectedStatus] = useState<StatusType[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const statusFromUrl = searchParams.get('status');
  const { searchWord, isDesktopFilter } = useSearchStringContext();

  useEffect(() => {
    const statusFromUrlList = (statusFromUrl?.split(',') as StatusType[]) ?? [];
    statusFromUrlList && setSelectedStatus(statusFromUrlList);
  }, [statusFromUrl]);

  useEffect(() => {
    if (selectedStatus.length && shouldUpdateUrl(selectedStatus)) {
      searchParams.set('page', '1');
      searchParams.set('status', selectedStatus.join(','));
      if (searchWord !== '' && isDesktopFilter) {
        searchParams.set('searchString', searchWord);
      } else if (searchWord === '' && isDesktopFilter) {
        searchParams.delete('searchString');
      }
    }
    setSearchParams(searchParams);
  }, [selectedStatus]);

  const shouldUpdateUrl = (selectedStatus: string[]) => {
    const status = statusFromUrl?.split(',') ?? [];

    return !isEqualLists(status, selectedStatus);
  };

  const handleOnChange = (status: StatusType) => {
    const updatedSelection = new Set(selectedStatus);

    updatedSelection.has(status)
      ? updatedSelection.delete(status)
      : updatedSelection.add(status);

    if (!updatedSelection.size) {
      searchParams.set('page', '1');
      searchParams.delete('status');
      if (searchWord !== '' && isDesktopFilter) {
        searchParams.set('searchString', searchWord);
      } else if (searchWord === '' && isDesktopFilter) {
        searchParams.delete('searchString');
      }
      setSearchParams(searchParams);
    }

    setSelectedStatus([...updatedSelection]);
  };

  return (
    <>
      {Object.entries(StatusLabels).map(([status, label]) => {
        return (
          <Checkbox
            key={status}
            type={label}
            isSearchPage={true}
            total={statusItems?.find((item) => item.id === status)?.total}
            toggleFn={() => handleOnChange(status as StatusType)}
            isChecked={selectedStatus.includes(status as StatusType)}
          />
        );
      })}
    </>
  );
}
