import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EuropeIcon } from '../../../../../images/icon/europe.svg';
import { ReactComponent as NorwayIcon } from '../../../../../images/icon/norway.svg';

interface Props {
  readonly isSentToTed: boolean | undefined;
}

export default function ThresholdIcon({ isSentToTed }: Props) {
  const { t } = useTranslation('translations');
  return (
    <div data-cy="threshold_icon">
      {isSentToTed && (
        <abbr title={t('notice.origin_europe')}>
          <EuropeIcon />
        </abbr>
      )}
      {!isSentToTed && (
        <abbr title={t('notice.origin_norway')}>
          <NorwayIcon />
        </abbr>
      )}
    </div>
  );
}
