import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import css from './OriginChip.module.scss';

interface Props {
  readonly isSentToTed: boolean | null | undefined;
}

export default function OriginChip({ isSentToTed }: Props): ReactElement {
  const { t } = useTranslation('translations');

  const getChipText = () => {
    return isSentToTed ? t('notice.origin_europe') : t('notice.origin_norway');
  };

  return (
    <>
      {isSentToTed !== null && (
        <span data-cy="origin_chip" className={css.chip_content}>
          <span
            className={classnames(
              css.icon,
              isSentToTed ? css.europe : css.norway
            )}
            aria-hidden="true"
          ></span>
          {getChipText()}
        </span>
      )}
    </>
  );
}
