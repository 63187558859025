import React, { ReactElement, useState } from 'react';
import { ReactComponent as SmallCrossIcon } from '../../../../../../images/icon/cross-small.svg';
import { useTranslation } from 'react-i18next';

import css from './Chip.module.scss';
import { ChipContent } from '../ChipList';
import { Location, ChipItem } from 'models';
import useLocations from 'shared/useLocations';
import useSearch from 'shared/useSearch';

interface Props {
  readonly chipFn: (chip: ChipContent | undefined) => void;
  chip?: ChipContent;
  readonly isClearAllChip?: boolean;
}

export function getChipType(
  chip: ChipContent | undefined,
  t: (key: string) => string
): string {
  switch (chip?.facetKey) {
    case 'type':
      return t('filter.procurement');
    case 'status':
      return t('filter.status.title');
    case 'contractNature':
      return t('filter.contract_nature.title');
    case 'cpvCodesLabel':
      return t('common.cpvCodesLabel');
    case 'cpvCodesId':
      return t('common.cpvCodesId');
    case 'issueDate':
      return t('filter.date');
    case 'location':
      return t('filter.location');
    case 'buyer':
      return t('common.buyer');
    case 'winner':
      return t('common.winner');
    default:
      return '';
  }
}

export function getChipText(
  chip: ChipContent | undefined,
  locations: Location[] | undefined,
  cpvCategoryLabel: string,
  cpvCategoryId: string,
  buyerName: string,
  winnerName: string,
  t: (key: string) => string
): string {
  const mainCategory = ['RESULT', 'COMPETITION', 'PLANNING'];
  const typePrefix = mainCategory.includes(chip?.value ?? '')
    ? 'type.'
    : 'subtype.';
  const typeText = chip?.facetKey
    ? typePrefix + chip.value?.toString().toLowerCase()
    : '';

  switch (chip?.facetKey) {
    case 'type':
      return t(typeText ?? '');
    case 'status':
      return t(`filter.status.${chip.value}`.toLowerCase());
    case 'contractNature':
      return t(`filter.contract_nature.${chip.value}`.toLowerCase());
    case 'location': {
      const location: Location | undefined = locations?.find(
        (location) => location.id === chip?.value
      );
      return location?.name ?? (chip?.value as string);
    }
    case 'cpvCodesLabel': {
      return cpvCategoryLabel;
    }
    case 'cpvCodesId': {
      return cpvCategoryId;
    }
    case 'buyer': {
      return buyerName;
    }
    case 'winner': {
      return winnerName;
    }
    default:
      return chip?.value as string;
  }
}

export default function Chip(props: Props): ReactElement {
  const { chip, chipFn, isClearAllChip } = props;
  const { t } = useTranslation('translations');
  const { flatLocations: locations } = useLocations();
  const { data: response } = useSearch();

  const [buyerName, setBuyerName] = useState('');
  const [winnerName, setWinnerName] = useState('');
  const [cpvCategoryLabel, setCpvCategoryLabel] = useState('');
  const [cpvCategoryId, setCpvCategoryId] = useState('');

  const setChipNames = () => {
    switch (chip?.facetKey) {
      case 'buyer':
        if (!buyerName) {
          response?.activeFacets.buyer.items.forEach((buyer: ChipItem) => {
            if (buyer.id === chip?.value) {
              setBuyerName(buyer.value);
            }
          });
        }
        break;
      case 'winner':
        if (!winnerName) {
          response?.activeFacets.winner.items.forEach((winner: ChipItem) => {
            if (winner.id === chip?.value) {
              setWinnerName(winner.value);
            }
          });
        }
        break;
      case 'cpvCodesLabel':
        if (!cpvCategoryLabel) {
          response?.activeFacets.cpvCodesLabel.items.forEach(
            (cpvCategory: ChipItem) => {
              if (cpvCategory.id === chip?.value) {
                const textValue = cpvCategory.value.includes('null - ')
                  ? cpvCategory.value.substring(7)
                  : cpvCategory.value;
                setCpvCategoryLabel(chip?.value + ' - ' + textValue);
              }
            }
          );
        }
        break;
      case 'cpvCodesId':
        if (!cpvCategoryId) {
          response?.activeFacets.cpvCodesId.items.forEach(
            (cpvCategory: ChipItem) => {
              if (cpvCategory.id === chip?.value) {
                setCpvCategoryId(cpvCategory.value);
              }
            }
          );
        }
        break;
    }
  };

  setChipNames();

  return (
    <button
      className={isClearAllChip ? css.chip_blue : css.chip_light}
      onClick={() => chipFn(chip)}
      aria-label={
        isClearAllChip
          ? t('filter.clear')
          : `${t('filter.remove')}: ${getChipType(chip, t)}: ${getChipText(
              chip,
              locations,
              cpvCategoryLabel,
              cpvCategoryId,
              buyerName,
              winnerName,
              t
            )}`
      }
      data-cy="chip"
    >
      <span className="filter_text">
        {isClearAllChip
          ? t('filter.clear')
          : getChipText(
              chip,
              locations,
              cpvCategoryLabel,
              cpvCategoryId,
              buyerName,
              winnerName,
              t
            )}
      </span>
      <SmallCrossIcon />
    </button>
  );
}
