import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as HelpIcon } from '../../../images/icon/help.svg';
import { ANSKAFFELSER_SEARCH_TIPS_URL } from 'shared-config';
import css from '../HelpLinks.module.scss';

export default function SearchTips() {
  const { t } = useTranslation('translations');

  return (
    <div data-cy="search-tips">
      <a
        href={ANSKAFFELSER_SEARCH_TIPS_URL}
        target="_blank"
        className={css.link}
      >
        {t('search.search_tips')}
        <HelpIcon />
      </a>
    </div>
  );
}
