import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { CODES, CPV_CODES } from 'shared-config';
import { CpvCode, OptionType } from 'models';
import http from './http-common';

const flattenCodes = (array: CpvCode[]): CpvCode[] => {
  return array.reduce((acc: CpvCode[], element: CpvCode) => {
    return element.children
      ? [...acc, element, ...flattenCodes(element.children)]
      : [...acc, element];
  }, []);
};

const fetchCpvCodes = async () => {
  return await http
    .get(`${CODES}/${CPV_CODES}`)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(res.statusText);
      }
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

function useCPV() {
  const { data, status } = useQuery<CpvCode[]>(['cpvCodes'], fetchCpvCodes, {
    staleTime: Infinity
  });

  const cpvCodes = useMemo(() => {
    if (data && data.length > 0 && status == 'success') {
      const flatten = data && flattenCodes(data);
      const optionsFromCPV: OptionType[] = flatten.map((cpvCode) => ({
        value: cpvCode.id,
        label: cpvCode.label
      }));
      return optionsFromCPV;
    }
  }, [status, data]);

  return { status, cpvCodes, data };
}

export default useCPV;
