import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Notice } from 'models';
import { NOTICES } from 'shared-config';
import http, { buildUrl } from 'shared/http-common';

const useNotices = (id: string) => {
  const { i18n } = useTranslation('translations');

  async function fetchNotices() {
    const response = await http.get<Notice>(buildUrl([NOTICES, id]));
    return response.data;
  }

  const { status, data } = useQuery<Notice, Error>(
    [NOTICES, id, i18n.language],
    fetchNotices
  );

  return { data, status };
};

export default useNotices;
