import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import css from './GraphCard.module.scss';
import { ReactComponent as BarChart } from '../../../../../images/icon/bar-chart.svg';
import { ReactComponent as Timeline } from '../../../../../images/icon/timeline.svg';
interface Props {
  readonly graph;
}

export default function GraphCard({ graph }: Props): ReactElement {
  const { t } = useTranslation('translations');
  const urlName = t('doffindata.' + graph.name, { lng: 'en' }).replaceAll(
    ' ',
    '_'
  );
  let icon = <Timeline />;

  switch (graph.name) {
    case 'graphcard.title_card1':
      break;
    case 'graphcard.title_card2':
      break;
    case 'graphcard.title_card3':
      icon = <BarChart />;
      break;
    case 'graphcard.title_card4':
      icon = <BarChart />;
      break;
    default:
      break;
  }

  return (
    <Link
      to={`/data/view?graph=${urlName.toLowerCase()}`}
      className={classNames(css.graph_card)}
      data-cy="graphcard"
    >
      <div className={css.content}>
        <div className={css.icon}>{icon}</div>
        <h3 className={css.card_title}>{t('doffindata.' + graph.name)}</h3>
        <p className={css.card_desc}>{t('doffindata.' + graph.description)}</p>
      </div>
    </Link>
  );
}
