import React from 'react';
import * as pbi from 'powerbi-client';

import { BASE_URL } from 'shared-config';

interface EmbedTokenResponse {
  accessToken: string;
  embedUrl: string[];
}

export default async function getEmbedToken(
  reportId: string,
  embedContainer: React.RefObject<HTMLDivElement>
): Promise<void> {
  try {
    const { accessToken, embedUrl } = await fetchEmbedToken(reportId);

    const embedConfig = {
      type: 'report',
      tokenType: pbi.models.TokenType.Embed,
      accessToken,
      embedUrl: embedUrl[0], // Adjust based on your response structure
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false
      }
    };

    if (embedContainer.current) {
      const powerbiService = initializePowerBIService();
      powerbiService.embed(embedContainer.current, embedConfig);
    } else {
      throw new Error('Embed container not available');
    }
  } catch (error) {
    console.error('Error embedding the report:', error);
  }
}

async function fetchEmbedToken(reportId: string): Promise<EmbedTokenResponse> {
  const response = await fetch(
    `${BASE_URL}/getEmbedToken?reportId=${reportId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch embed token');
  }

  return response.json();
}

function initializePowerBIService() {
  return new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory
  );
}
